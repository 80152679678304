.single-content {
    padding-top: 20px;
}

.updated {
    display: block;
    font-size: 10px;
    margin: 30px 0;
}

.post {
    &__pagination {
        padding: 0;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        li {
            list-style-type: none;
            &.next {
                margin-left: auto;
            }
        }
        span, a {
            color: black;

        }
        .current {
            font-weight: bold;
        }
        .prev,
        .next {
            a {
                display: flex;
                align-items: center;
                color: #365596;
                font-style: italic;
                font-size: 12px;
                text-decoration: none!important;
            }
        }
        .next {
            a {
                margin-right: 30px;
                &::after {
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='18' viewBox='0 0 24 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.2868 7.5124H1.33333C0.596954 7.5124 0 8.17842 0 9C0 9.82158 0.596954 10.4876 1.33333 10.4876H19.2868L14.9434 15.3335C14.3967 15.9435 14.3967 16.9325 14.9434 17.5425C15.4901 18.1525 16.3765 18.1525 16.9233 17.5425L23.5899 10.1045C24.1367 9.4945 24.1367 8.5055 23.5899 7.89551L16.9233 0.457494C16.3765 -0.152498 15.4901 -0.152498 14.9434 0.457494C14.3967 1.06749 14.3967 2.05648 14.9434 2.66647L19.2868 7.5124Z' fill='%23365596'/%3E%3C/svg%3E%0A");
                    background-size: 100%;
                    width: 12px;
                    height: 9px;
                    display: block;
                    margin-left: 10px;
                }
            }
        }
        .prev {
            a {
                margin-left: 30px;
                &::before {
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='18' viewBox='0 0 24 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.71323 7.5124H22.6667C23.403 7.5124 24 8.17842 24 9C24 9.82158 23.403 10.4876 22.6667 10.4876H4.71323L9.05662 15.3335C9.60335 15.9435 9.60335 16.9325 9.05662 17.5425C8.50988 18.1525 7.62345 18.1525 7.07672 17.5425L0.410051 10.1045C-0.136684 9.4945 -0.136684 8.5055 0.410051 7.89551L7.07672 0.457494C7.62345 -0.152498 8.50988 -0.152498 9.05662 0.457494C9.60335 1.06749 9.60335 2.05648 9.05662 2.66647L4.71323 7.5124Z' fill='%23365596'/%3E%3C/svg%3E%0A");
                    background-size: 100%;
                    width: 12px;
                    height: 9px;
                    display: block;
                    margin-right: 10px;
                }
            }
        }
    }
}

.entry__postmeta {
    border: 1px solid #eee;
    border-width: 1px 0;
    padding: 10px 0;
    display: flex;
    margin: 20px 0 25px;

    > div {
        border-right: 1px solid #eee;
        padding: 0 10px;

        &:first-of-type {
            padding-left: 0;
        }

        &:last-of-type {
            padding-right: 0;
            border-right: none;
        }
    }
}