.section-table-mini {
    overflow: hidden;

    .box {
		ol {
		    margin: 0;
		    padding: 0;
		}    

		.item {
		    background: #fbfbfb;
		    padding: 10px;
		    border-bottom: 1px solid #ccc;
		    margin-bottom: 3px;
		    overflow: hidden;
		    width: 100%;

		    .column {
			    float: left;

			    &.logo img {
				    border-radius: 4px;
				    margin-right: 7px;
				}

				&.bonus-text {
					h4 {
					    color: $orange;
					    font-size: 16px;
					    margin-bottom: 1px;
					}

					p {
					    color: $black;
					    font-size: 16px;
					    line-height: 1.2em;
					    margin-bottom: 0;

					    i {
						    font-style: normal;
						    font-weight: 400;
						    font-size: 12px;
						}
					}
				}

				&.button {
					a {
					    font-size: 12px;
					}
				}
			}
		}	
    }

	.logo {
	    width: 35px;
	}  

	.bonus-text {
	    width: calc(100% - 160px);
	    padding: 0 12px;
	}  

	.button {
	    width: 125px;
	}

	.read-more {
	    float: right;
	}
}
