.poolspel {
    & > .row {
        @include media-breakpoint-down(md) {
            flex-direction: column-reverse;
        }
    }
    &__left-box {
        //margin-right: 10px;
        border-top: 5px solid #ef4136;
        background: #f3f3f3;
        padding: 20px 30px;
        h3 {
            color: #000;
            font-size: 26px;
            margin-bottom: 15px;
        }
    }
    &__box-row {
        color: #000;
        margin-bottom: 8px;
        span {
            &:first-of-type {
                font-weight: bold;
            }
        }
    }
    &__postmeta {
        border: 1px solid #eee;
        border-width: 1px 0;
        padding: 10px 0;
        display: flex;
        margin: 20px 0 25px;
        > div {
            border-right: 1px solid #eee;
            padding: 0 10px;
            &:first-of-type {
                padding-left: 0;
            }
            &:last-of-type {
                padding-right: 0;
                border-right: none;
            }
        }
    }
    &__content {
        //background: #f3f3f3;
        &,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        a {
            color: rgba(0,0,0,.7);
        }
        a {
            text-decoration: underline;
        }
    }
    &__thumbnail-img {
        margin-bottom: 30px;
        width: 100%;
    }
    &__casino-img {
        margin: 16px 0;
    }
    &__cta {
        display: flex;
        padding: 15px 60px;
        line-height: 1.4em;
        font-size: 22px;
        background: #3dbf1f;
        border: 1px solid #3dbf1f;
        color: #fff!important;
        box-shadow: 0 1px 0 0 #fff;
        width: 100%;
        font-weight: bold;
        justify-content: center;
        text-transform: uppercase;
        text-decoration: none!important;
        margin-bottom: 20px;
        &:hover {
            text-decoration: none;
            background: #22ad01;
            transition-duration: .1s;
        }
    }
    .sidebar {
        position: relative;
        margin-bottom: 15px;
    }
}