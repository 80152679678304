/**
 *
 * FONT SMOOTHING
 *
 * Changes the font rendering,
 * allowing thinner fonts
 */

@mixin fontSmoothing {
  /*text-shadow: 1px 1px 1px rgba(0,0,0,.004);*/
  text-rendering: optimizeLegibility!important;
  -webkit-font-smoothing: antialiased!important;
}

/**
 * MEDIA QUERIES
 * -------------
 */

@mixin phoneOnly {
  @media (max-width: #{$phone-width - 1px}) {
    @content;
  }
}

@mixin upToTablet {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin upToDesktop {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin upToWide {
  @media (max-width: #{$wide-width - 1px}) {
    @content;
  }
}

@mixin fromSmall {
  @media (min-width: #{$small-width} ) {
    @content;
  }
}

@mixin fromTablet {
  @media (min-width: #{$tablet-width} ) {
    @content;
  }
}

@mixin fromDesktop {
  @media (min-width: #{$desktop-width}  ) {
    @content;
  }
}

@mixin fromWide {
  @media ( min-width: #{$wide-width} ) {
    @content;
  }
}

@mixin clearFix {
  zoom: 1;
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}