.announcement {
  overflow: hidden;
  position: relative;
  & > * {
    z-index: 2;
    position: relative;
  }
  .row {
    justify-content: center;
  }
  p {
    color: black;
    text-align: center;
    margin: 0;
  }
  &__image {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
