.container.table-section .breadcumb {
  margin-top: 20px !important;
  margin-bottom: 0;
}

.page-template-template-sections .breadcumb {
  margin-bottom: 0px;
  margin-top: 20px;
}

.single-list .breadcumb {
  margin-bottom: 0;
  margin-top: 20px;
}

.social_links {
  width: 6%;
  float: right;
}

.twitter.social_links {
  margin-left: 10px;
}

.archive.category .breadcumb {
  margin-top: 20px;
}

.archive.page .breadcumb {
  padding-top: 20px;
}

.page-template-template-article-archive .breadcumb {
  margin-bottom: 0;
  margin-top: 20px;
}

.list__header {
  margin-top: 13px;
}

.hero__container.text-center {
  text-align: center;
  width: 100%;
  left: 0;
}

.category .archive__list>.row div:first-child {
  min-height: 0;
}

span.title.japanese_title.hidden-lg {
  display: list-item;
}

.casino_item {
  padding-bottom: 12px;
  padding-top: 12px;
}


time.updated {
  margin-bottom: 10px;
}

.single-post h1.entry-title {
  font-size: 33px;
}

.casino-bonus__box p.title {
  font-weight: bold;
}

.stars span.active {
  display: inline-block;
  color: #ffba00;
}

.stars span {
  display: none;
}

.two-lists .btn {
  margin-bottom: 4px;
}

#mc_embed_signup .mc-field-group input {
  background: #0b142a;
}

#mc_embed_signup .mc-field-group input {
  display: block;
  width: 99%;
  padding: 8px 0;
  text-indent: 2%;
  border: 2px solid #0b142a;
  color: white;
  font-family: inherit;
  font-size: 13px;
}

#mc_embed_signup .button {
  clear: both;
  background-color: #ffa500;
  border: none;
  border-radius: 4px;
  transition: all 0.23s ease-in-out 0s;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: normal;
  height: 32px;
  line-height: 32px;
  margin: 0 0px 0px 0;
  padding: 0px 14px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  white-space: nowrap;
  width: auto;
}

#mc_embed_signup div.mce_inline_error {
  width: 99%;
}

p.comment-form-author {
  width: 80%;
  float: left;
}

.comment-respond {
  padding-bottom: 50px;
  margin-top: 20px;
}

.comments p.form-submit {
  width: 20%;
  float: right;
}

.comments textarea {
  font-size: 13px;
}

.comments input#submit {
  float: right;
  width: 100%;
  background: #cd3097;
  padding: 10px;
  color: white;
  box-shadow: none;
  border: 1px solid #cd3097;
  border-radius: 10px;
}

.comment_item {
  margin: 18px auto;
  background-color: #0d1832;
  padding: 20px;
}

.comment_item:first-child {
  margin-top: auto;
}

.comment_item:last-child {
  margin-bottom: auto;
}

.comment_author.row {
  margin-bottom: 16px;
}

.breadcumb {
  //margin-bottom: 15px;
  color: #8b97b4;
}

.breadcumb a {
  color: #8b97b4;
}

@media (max-width: 1199px) {
  .videos iframe {
    width: 100%;
  }

  .row.casino-review__row {
    display: block;
  }

  .casino-bonus__box {
    margin-top: 22px;
  }

  a.btn.casino-review__button {
    width: 47%;
    font-size: 12px;
  }

  .three-casinos__container {
    margin-bottom: 15px;
  }

  .two-lists li.item:before {
    display: none;
  }

  .filter__button {
    margin-bottom: 10px;
  }

  .two-lists .column.logo {
    width: 12%;
    margin-right: 5px;
  }

  .lastest-news_subtitle {
    padding: 0;
  }

  .two-lists li.item .column.logo a img {
    width: 100%;
    height: auto;
  }

  .two-lists li.item {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .two-lists li.item {
    padding-right: 6px;
    padding-left: 6px;
    border-bottom: 1px solid #2a334b;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .two-lists li.item:last-child {
    border-bottom: none;
  }

  .two-lists li.item a.btn {
    padding: 3% 5%;
    font-size: 12px;
    /* vertical-align: middle; */
    top: 13px;
    position: relative;
    width: 100%;
    text-align: center;
  }

  .item.nyheter img {
    width: 100%;
  }

  .social_links {
    float: none;
    display: inline-block;
    bottom: 17px;
    position: relative;
  }

  .two-lists li.item .column.button {
    width: 25%;
    float: right;
  }

  .two-lists .column.text {
    width: 59%
  }

  .section.latest-news .col-sm-3.col-xs-6 {
    padding: 5px;
  }

  .section.latest-news {
    padding-right: 10px;
    padding-left: 10px;
  }

  .section.latest-news .col-sm-6 {
    padding: 0;
  }

  h3.hero__title {
    font-size: 24px;
  }

  h5.hero__subtitle {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  two-lists li.item .column.text .title {
    font-weight: bold;
  }

  .latest-news .item .column.body {
    padding: 9px 10px;
  }

  .latest-news .item {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  section.videos .col-lg-4 {
    margin-bottom: 10px;
  }

  .widget_shame_widget {
    margin-bottom: 25px;
  }

  ul.casino-proscons__pros {
    margin-bottom: 12px;
  }


  .list__list .item .column.logo {
    width: 36%;
    display: block;
    margin: auto;
    margin-bottom: 25px;
  }

  .list__list .item .column.text .stars {
    clear: both;
    display: block;
    text-align: center;
    margin-bottom: 5px;
  }

  .list__list .item .column.text {
    width: 70%;
    display: block;
    margin: auto;
    text-align: center;
    color: white;
    margin-bottom: 25px;
  }

  .list__list .item .column.text br {
    display: none;
  }

  .list__list .item .column.bonus {
    width: 70%;
    margin: auto;
    display: block;
    text-align: center;
    margin-bottom: 25px;
  }

  .list__list .item .column.text .title a {
    margin-right: 0 !important;
  }

  .list__list .item .column.button {
    width: 46%;
    margin: auto;
    display: block;
    float: none !important;
  }

  .list__list .item .column.button a {
    padding-left: 0;
  }

  .list__list .item .column.logo a img {
    height: auto;
    width: 100%;
  }

  .list__list .item .column.button a {
    width: 100%;
  }

  .archive .page_navigation.navigation {
    width: 77%;
    display: block;
    height: 40px;
  }

  .list__list .custom_shortcode .column.logo {
    padding-top: 13px;
  }

  .list__list .custom_shortcode .column.text {
    padding-top: 0px;
  }

  .list__list .custom_shortcode .column.button .btn {
    font-size: 12px;
  }
}

.list__list {
  &.message-box {
    .custom_shortcode {
      padding-top: 14px;
      .column {
        display: block;
        text-align: center;

        .btn {
          float: none;
          margin-bottom: 0;
        }

        &:nth-child(2) {
          margin-top: 10px;
        }
      }
    }
  }
}

a.btn.casino-review__button:last-child {
  margin-left: 10px;
}

span.title {
  font-weight: bold;
}

.section.casino-intro {
  padding-bottom: 0;
}

.column.button {
  padding-right: 0 !important;
}

div.section.latest-news > div > div:nth-child(1) {
  margin-bottom: 5px;
}

section.section.three-casinos {
  padding-top: 0;
}

section h3 {
  font-size: 20px;
}

section.videos > div > div:nth-child(1) > div {
  margin-bottom: 15px;
}

.page_navigation.navigation {
  margin: auto;
  width: 26%;
  display: block;
  height: 20px;
}

.page_navigation.navigation div {
  float: left;
  width: 17%;
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}

.page_numbers {
  width: 66% !important;
  display: inline-flex;
}

.page_numbers a {
  display: inline-block;
  width: 21%;
  font-size: 12px;
  top: 2px;
  position: relative;
}

.page_numbers span {
  font-size: 12px;
  width: 21%;
  display: inline-block;
  top: 2px;
  position: relative;
  color: #8e8e8e;
}

.dotcenter {
  position: relative;
  bottom: 2px;
}

table {
  border-color: #1e2e4e;
  min-width: 90%;
  border-spacing: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.custom_box{
  background: #1e2e4e;
  padding: 15px;
  margin: 20px 0;
}

.contact .archive__list>.row div {
  min-height: 0;
}

form#gform_1 input, form#gform_1 textarea {
  background-color: #0b102a;
  border: none;
  color: white;
}

div#gform_wrapper_1 {
  background: #142346;
  padding: 20px;
}

form#gform_1 input#gform_submit_button_1 {
  clear: both;
  background-color: #5a6581;
  border: none;
  border-radius: 4px;
  -webkit-transition: all .23s ease-in-out 0s;
  transition: all .23s ease-in-out 0s;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  height: 39px;
  line-height: 32px;
  margin: 0;
  padding: 0 51px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  white-space: nowrap;
}

body .ubermenu-main .ubermenu-submenu .ubermenu-item-header > .ubermenu-target:hover {
  color: #cd3096;
}

body .ubermenu .ubermenu-target-text {
  font-weight: 400;
}

body .ubermenu-skin-minimal.ubermenu-horizontal .ubermenu-item-level-0 > .ubermenu-submenu-drop {
  border-top: 1px solid #cd3096;
}

.latest-news .item .column.body {
  min-height: 130px;
}

.archive .item .column.body {
  min-height: 125px;
}
