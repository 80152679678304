.author {
    &, h1, h3, h4, h5 {
        color: #212529;
    }
    h1 {
        font-size: 1.5em;
        margin: 15px 0 20px;
    }
    h3 {
        font-size: 12px;
        color: $color-blue2;
        font-weight: 700;
        margin: 0;
        text-transform: uppercase;
    }
    .btn {
        width: 100%;
    }
    &__twitter {
        display: inline-block;
        margin-top: 20px;
        font-size: 18px;
    }
    &__description {
        margin-top: 25px;
    }
    &__image {
        width: 100%;
    }
    &__section {
        hr {
            border-top: 1px solid $color-blue2;
            margin-top: 15px;
            margin-bottom: 30px;
        }
    }
    &__table {
        width: 100%;
        table {
            margin: 0;
            min-width: 100%;
            margin-bottom: 30px;
        }
        h5 {
            font-size: 14px;
            color: #5c6ca3;
            font-weight: 700;
            margin-bottom: 8px;
        }
        tbody {
            tr {
                display: flex;
            }
            td {
                font-size: 13px;
                a {
                    text-decoration: underline;
                    font-weight: 300;
                    line-height: 1.1;
                    display: inline-block;
                }
                &:first-of-type {
                    flex-grow: 1;
                    //flex-basis: 80%;
                }
                &:last-of-type {
                    //flex-grow: 1;
                    //flex-basis: 20%;
                }
            }
        }
    }
}