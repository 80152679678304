/* Width */
$small-width:		 320px;
$phone-width:        480px;
$tablet-width:       768px;
$desktop-width:      1024px;
$wide-width:         1200px;

$trans-speed : .2s;

/* Height */
$header-height:		74px;

/* Standard Colours */
$black:	#231f20;
$white:	#fff;

/* Colour Palette */
$light-blue: #142346;
$blue: #0D1832;
$dark-blue: #0B142A;

$pink: #CD3097;
$green:	#30CD90;
$purple: #663399;
$orange: #E4882E;

$color-blue2: #4F6CA9;
$color-gray: #585C5F;

$dark-green:		#186d04;
$light-green:		#2aa20c;

$dark:				#232323;
$darker: 			#1d1d1d;

$grey:				#3c3c3c;
$light-grey:		#eeeded;
$form-grey: 		#cdcecd;
$medium-grey:		#b0b6b6;


/* Fonts */
$font-body: 'Open Sans', Arial, sans-serif;
$font-heading: 'Roboto', Arial, sans-serif;

$font-size-body: 14px;
