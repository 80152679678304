.archive {


	.filter {
		margin-bottom: 30px;

		&__button {
			border: 1px solid #1E2D51;
			border-radius: 7px;
			cursor: pointer;
			font-size: $font-size-body;
			height: 50px;
			letter-spacing: 1.3px;
			line-height: 50px;
			text-align: center;

			img{
				position: relative;
				bottom: 2px;
			}
		}
	}

	.item {
	    overflow: hidden;
	    padding-bottom: 20px;

		.column {

		    &.image {
		    	background-position: center;
		    	background-size: cover;
		    	height: 240px;
			    position: relative;

			    a {
			    	position: absolute;
			    		top: 0;
			    		left: 0;
			    		bottom: 0;
			    	width: 100%;
			    	z-index: 2;
			    }
			}

			&.body {
				background-color: #1E2D51;
				color: #8B97B4;
			    padding: 20px;

			    .info {
				    margin-bottom: 5px;

				}
			}
		}

		@include upToDesktop {
			margin-bottom: 20px;
		}
	}
}