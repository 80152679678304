.table__container {
  border-bottom: 1px solid $color-blue2;
  margin-bottom: 13px;
  @include media-breakpoint-down(sm) {
    border-bottom: none;
  }
  th {
    font-size: 15px !important;
  }
  tr {
    &:nth-child(2n) {
      background: rgba(79, 108, 169, 0.1);
    }
  }
  td {
    font-size: 12px !important;
    line-height: 18px !important;
    &,
    a {
      color: $color-gray !important;
    }
    border-bottom: none !important;
  }
  td[class$='__col-game_stop'] {
    display: flex;
    flex-direction: column;
    .date {
      color: $color-blue2 !important;
    }
    .time {
    }
  }
  tbody {
    td {
      a {
        display: flex;
        align-items: center;
        > span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  td[class$='__col-title'] {
    & > span {
      display: flex;
      flex-direction: column;
      @include media-breakpoint-down(sm) {
        & > span {
          margin-top: 3px;
        }
      }
    }
    a {
      color: $color-blue2 !important;
    }
  }
  td[class$='__col-author'] {
    white-space: nowrap;
    img {
      border-radius: 50%;
      width: 25px;
      height: 25px;
      object-fit: cover;
      margin-right: 10px;
    }
  }
  td[class$='__col-odds'] {
    span {
      color: #fafafa;
      background-color: $color-blue2;
      padding: 3px 9px;
      border-radius: 12px;
      white-space: nowrap;
    }
  }
  @include media-breakpoint-down(sm) {
    thead {
      tr {
        display: flex;
        th[class$='__header-game_stop'] {
          width: 50%;
        }
        th[class$='__header-author'] {
          padding-left: 0;
        }
        th[class$='__header-author'],
        th[class$='__header-game_stop'] {
          display: block;
        }
      }
    }
    tbody {
      tr {
        position: relative;
        display: grid;
        margin: 0;
        grid-template: repeat(4, auto) / repeat(2, 50%);
        padding: 0 0 14px;
        border: none !important;
        td {
          font-size: 18px !important;
        }
        td[class$='__col-game_stop'] {
          grid-column: 1;
          grid-row: 1;
          padding: 14px 0 14px 8px;
          .time {
            color: #a3a8ac !important;
            margin-top: 3px;
          }
        }
        td[class$='__col-author'] {
          grid-column: 2;
          grid-row: 1;
          padding: 14px 8px 14px 0;
          a {
            img {
              width: 40px;
              height: 40px;
            }
            span {
              font-size: 14px;
            }
          }
        }
        td[class$='__col-game_stop'],
        td[class$='__col-author'] {
          position: relative;
          &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            border-bottom: 1px solid #f2f2f2;
          }
        }

        td[class$='__col-title'] {
          grid-column: 1 / span 2;
          grid-row: 2;
          padding: 14px 8px 10px 8px;
        }
        td[class$='__col-game_type'] {
          grid-column: 1 / span 2;
          grid-row: 3;
          padding: 0 8px 10px 8px;
        }
        td[class$='__col-casino'] {
          padding: 0 0 0 8px;
          grid-column: 1;
          grid-row: 4;
        }
        td[class$='__col-odds'] {
          padding: 0 8px 0 0;
          text-align: right;
          display: inline-flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;
          grid-column: 2;
          grid-row: 4;
          span {
            padding: 5px 12px;
          }
        }
        td[class$='__col-title'],
        td[class$='__col-casino'],
        td[class$='__col-game_type'] {
          &::before {
            font-size: 14px;
            margin-bottom: 2px;
            content: attr(data-title);
            display: block;
            color: #979797;
          }
        }
        &:nth-of-type(2n) {
          td[class$='__col-game_stop'],
          td[class$='__col-author'] {
            &::before {
              border-color: white;
            }
          }
        }
        td[class$='__col-author'],
        td[class$='__col-odds'] {
          padding-right: 16px;
        }
        td[class$='__col-casino'],
        td[class$='__col-game_stop'] {
          padding-left: 16px;
        }
        td[class$='__col-title'],
        td[class$='__col-game_type'] {
          padding-right: 16px;
          padding-left: 16px;
        }
      }
    }
  }
}

body.author {
  .table__container {
    @include media-breakpoint-down(sm) {
      tbody {
        tr {
          td[class$='__col-game_stop'] {
            grid-column: 1 / span 2;
          }
        }
      }
    }
  }
}

.oddslist__pagination,
.poolspellist__pagination,
.speltipslist__pagination {
  .prev,
  .next {
    a {
      display: flex;
      align-items: center;
      color: #365596;
      font-style: italic;
      font-size: 12px;
      text-decoration: none !important;
    }
  }
  .next {
    a {
      margin-right: 30px;
      &::after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='18' viewBox='0 0 24 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.2868 7.5124H1.33333C0.596954 7.5124 0 8.17842 0 9C0 9.82158 0.596954 10.4876 1.33333 10.4876H19.2868L14.9434 15.3335C14.3967 15.9435 14.3967 16.9325 14.9434 17.5425C15.4901 18.1525 16.3765 18.1525 16.9233 17.5425L23.5899 10.1045C24.1367 9.4945 24.1367 8.5055 23.5899 7.89551L16.9233 0.457494C16.3765 -0.152498 15.4901 -0.152498 14.9434 0.457494C14.3967 1.06749 14.3967 2.05648 14.9434 2.66647L19.2868 7.5124Z' fill='%23365596'/%3E%3C/svg%3E%0A");
        background-size: 100%;
        width: 12px;
        height: 9px;
        display: block;
        margin-left: 10px;
      }
    }
  }
  .prev {
    a {
      margin-left: 30px;
      &::before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='18' viewBox='0 0 24 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.71323 7.5124H22.6667C23.403 7.5124 24 8.17842 24 9C24 9.82158 23.403 10.4876 22.6667 10.4876H4.71323L9.05662 15.3335C9.60335 15.9435 9.60335 16.9325 9.05662 17.5425C8.50988 18.1525 7.62345 18.1525 7.07672 17.5425L0.410051 10.1045C-0.136684 9.4945 -0.136684 8.5055 0.410051 7.89551L7.07672 0.457494C7.62345 -0.152498 8.50988 -0.152498 9.05662 0.457494C9.60335 1.06749 9.60335 2.05648 9.05662 2.66647L4.71323 7.5124Z' fill='%23365596'/%3E%3C/svg%3E%0A");
        background-size: 100%;
        width: 12px;
        height: 9px;
        display: block;
        margin-right: 10px;
      }
    }
  }
}

.author-load-more__container {
  display: flex;
  justify-content: center;
  padding-top: 17px;
  .btn-load-more {
    color: #fafafa;
    background-color: $color-blue2;
    border-radius: 20px;
    padding: 6px 68px;
    font-style: italic;
    text-transform: none;
    border: none;
    width: auto !important;
  }
}
