@keyframes placeholder {
  0% {
    background-position: -600px 0;
  }
  100% {
    background-position: 600px 0;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -56;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -149;
  }
}

.fb-customerchat-button {
  background: none;
  border-radius: 60px;
  bottom: 16px;
  display: block;
  height: 36px;
  margin: 0px 12px;
  overflow: visible;
  padding: 0px;
  position: fixed;
  right: 12px;
  top: auto;
  width: 99px;
  z-index: 214748364444;
  box-shadow: rgb(0 0 0 / 15%) 0px 4px 12px 0px;
  cursor: pointer;

  & > div {
    background-color: rgb(245, 166, 35);
    align-items: center;
    border-radius: 60px;
    display: flex;
    height: 36px;
    padding: 0px 14px;
    position: absolute;
    width: fit-content;
    right: 0px;
    width: 100%;
    & > div {
      margin-left: -2px;
      margin-right: 4px;
      & > div {
        display: flex;
        align-items: center;
      }
    }
  }
  .loader {
    margin-right: 4px;
    margin-left: -2px;
    display: none;
    .spinning {
      animation: spin 2s linear infinite;
      .path {
        animation: dash 1.5s ease-in-out infinite;
        stroke: white;
        stroke-linecap: round;
      }
    }
  }
  &.loading {
    .loader {
      display: block;
      & + div {
        display: none;
      }
    }
    // & > div {
    //   // background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
    //   background: linear-gradient(
    //     to right,
    //     rgb(243, 173, 60) 8%,
    //     rgb(255, 196, 0) 18%,
    //     rgb(243, 173, 60) 33%
    //   );
    //   background-size: 1200px 100px;
    //   animation-duration: 1s;
    //   animation-fill-mode: forwards;
    //   animation-iteration-count: infinite;
    //   animation-name: placeholder;
    //   animation-timing-function: linear;
    // }
  }
  span {
    color: white;
    display: flex;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    user-select: none;
    white-space: nowrap;
  }
}
