.andelar {
  &__filters {
    .col {
      > div {
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 6px;
        margin: 0 0 20px;
        .deselect-all {
          display: flex;
          align-items: center;
          background: #e3e3e3;
          color: #000;
          &::after {
            content: '';
            display: block;
            background: url(../images/icon-close.svg) no-repeat center / contain;
            width: 12px;
            height: 100%;
            margin: 2px 0 0 6px;
          }
        }
        a,
        button {
          margin: 0;
          padding: 3px 9px;
          text-transform: none;
          &:hover {
            filter: brightness(1.05);
          }
        }
        a {
          // position: relative;
          // top: 0;
          // transition: 0.2s ease-in-out;
          // will-change: top;
          span {
            margin-left: 5px;
          }
          &:hover {
            filter: brightness(1.2);
            color: #fff;
            // top: -4px;
          }
          &:focus {
            box-shadow: none;
          }
          &:focus-visible {
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          }
          &.selected {
            outline: 3px solid rgba(0, 0, 0, 0.4);
            &.one {
              outline: 6px solid #e3e3e3;
            }
          }
        }
      }
    }
  }
  &__content {
    &.loading {
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        background: rgba(255, 255, 255, 0.6);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
      }
    }
  }
  &__user {
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style-type: none;
      }
    }
  }
  &__image {
    margin-bottom: 10px;
  }
  &__link {
    display: flex;
    line-height: 1;
    background-color: #818a91;
    font-size: 15px;
    padding: 12px 24px;
    border-radius: 3px;
    color: #fff;
    fill: #fff;
    text-align: center;
    transition: all 0.3s;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    margin-bottom: 15px;
    justify-content: center;
    position: relative;
    i {
      position: absolute;
      left: 24px;
      & + span {
        padding: 0 0 0 20px;
      }
    }
    &:hover {
      transform: translateY(8px);
      text-decoration: none;
      color: white;
    }
  }
}
