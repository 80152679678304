.sidebar-list {
  background-color: #142346;
  padding: 20px 18px;
  margin-bottom: 17px;

  h3 {
    font-size: 16px;
  }

  ul {
    padding: 0;
  }

  li {
    &.item {
      list-style-type: none;
      overflow: hidden;
      margin-bottom: 5px;
      padding: 5px 0;
      padding-bottom: 9px;
      border-bottom: 1px solid #2a334b;

      &:last-child {
        border-bottom: none;
      }

      .item-disclaimer {
        margin-top: 5px;
        p {
          font-size: 10px;
          margin: 0;
          line-height: 1.3;
          color: #b4b4b4;
        }
        a {
          text-decoration: underline;
          color: #b4b4b4;
        }
      }

      .column {
        display: inline-block;
        vertical-align: middle;

        &.logo {
          text-align: center;

          a {
            text-decoration: none;

            img {
              width: 50px;
              height: 50px;
              border-radius: 5px;
              vertical-align: middle;
              margin-right: 5px;
            }

            h4 {
              display: inline-block;
              font-weight: 600;
              color: $orange;
              font-size: 16px;
              vertical-align: middle;
            }
          }
        }

        &.text {
          color: #8b97b4;
          padding-left: 6px;

          .title {
            color: $white;
          }
          h5 {
            font-size: 15px;
            margin: 0;
          }
          span {
            font-size: 13px;
          }
        }

        &.button {
          padding-right: 10px;
        }
      }
    }
  }

  .column {
    &.logo {
      width: 17%;
    }

    &.text {
      width: 49%;
    }

    &.button {
      width: 31%;
    }
  }

  .more {
    text-align: center;
    text-transform: uppercase;
    margin-top: 13px;
    letter-spacing: 1px;
  }

  .btn {
    margin: 0;
    float: right;
  }
}
