.email-section {
	padding: 40px 0;
	text-align: center;
	background-size: cover;
	margin-top: 20px;
  	background-repeat: no-repeat;
}

.footer {
	background-color: $dark-blue;
	color: $white;
	padding-top: 15px;

	&__logo {
		padding-top: 30px;
	}

	h3 {
		display: none;
		color: $white;
		font-size: 15px;
		font-weight: 500;
		margin-bottom: 10px;
		text-transform: uppercase;
	}

	a {
		color: $white;
	}

	ul {
		font-size: $font-size-body;
  		line-height: 22px;
		list-style-type: none;
		padding: 0;

		li {
			list-style-type: none;

		}
	}

	.widget {
		font-size: 15px;
		line-height: 26px;
		padding-top: 23px;
		padding-bottom: 0;

		p {
			margin: 0;
		}
	}

	&__bottom {
		border-top: 1px solid rgba(255, 255, 255, .2);
		font-size: 14px;
		line-height: 52px;
		margin-top: 30px;
	}

	@include upToDesktop {
		text-align: center;
	}

	&__social {
		list-style-type: none;
		margin-top: 15px;

		li {
			display: inline-block;
			margin-right: 5px;
		}
	}
}