.casino-review {
	color: $white;

	&__container {
		padding: 38px 25px;
		background-color: #fff;
        position: relative;
        overflow: hidden;
        & > img {
            width: 100%;
            position: absolute;
            left: 0;
            display: block;
            top: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            object-fit: cover;
        }
	}

	&__row {
		display: -webkit-box;
  		flex-flow: row wrap;
        & > .col {
            p {
                margin: 0;
                font-size: 12px;
            }
            a {
                color: #fff;
                text-decoration: underline;
            }
        }
	}

	h1 {
		color: $white;
		font-family: $font-heading;
		font-size: 22px;
		font-weight: 500;
	}

	h2 {
		color: $white;
		font-family: $font-body;
		font-size: 22px;
		font-weight: 600;
	}

	.rating {
		margin-bottom: 20px;
	}

	.stars {
		display: inline-block;
	}

	.rating_number {
		display: inline;
		padding-left: 3px;
	}

	&__logo {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__button {
		background-color: rgba(255,255,255, 0.3);
		border: none;
		text-align: center;
		width: 200px;
		float: left;

		&.transparent {
			background-color: transparent;
			border: 1px solid $white;
		}
	}
}

.casino-proscons {
	padding-top: 0;

	ul,
	li {
		list-style-type: none;
		padding: 0;
	}

	&__pros {
		li {
			&:before {
				color: $green;
				content: '+';
				display: inline-block;
				width: 10px;
			}
			.pros_cons {
				padding-left: 10px;
				display: inline-table;
				width: 96%;
			}
		}
	}

	&__cons {
		li {
			&:before {
				color: $pink;
				content: '-';
				display: inline-block;
				width: 10px;
			}
			.pros_cons {
				padding-left: 10px;
				display: inline-table;
				width: 96%;
			}
		}
	}
}

.casino-bonus {

	&__box {
		padding: 20px;
		text-align: center;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

		.btn {
			border: none;
			margin-bottom: 0;
			float: none;
  			width: 130px;
		}

        .title {
            font-weight: bold;
            margin: 10px 0 5px;
        }

		.subtitle {
			font-size: 31px;
			font-weight: bold;
            margin-bottom: 20px;
		}

		&.pink {
			border: 1px solid $pink;

			.btn {
				background-color: $pink;
			}
		}

		&.purple {
			border: 1px solid $purple;

			.btn {
				background-color: $purple;
			}
		}

		&.orange {
			border: 1px solid $orange;

			.btn {
				background-color: $orange;
			}
		}
	}
}

.casino-features {
	border: 1px solid $light-blue;
	margin: 10px 0;
	padding: 25px;	

	ul,
	li {
		list-style-type: none;
		padding: 0;
	}

	h3 {
		font-size: 20px;
	}

	li {
		.left {
			display: inline-block;
			float: left;
			width: 30%;
		}

		.right {
			display: inline-block;
			float: left;
			width: 70%;
		}
	}

	&__list {
		margin-bottom: 20px;

		li {
			padding-bottom: 20px;
			@include clearFix;
		}
	}
}