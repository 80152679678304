.list {
	&__header {
		background-position: center;
		background-size: cover;
		margin: 20px 0;
		padding: 30px;
	}

	&__list {
		padding: 0;

		.item {
			border: 1px solid $purple;
			list-style-type: none;
			margin-bottom: 15px;
			padding: 30px 20px;
			position: relative;

			.ribbon {
				max-width: 200px;
				position: absolute;
				top: 0;
				left: 0;
			}

			&.featured {
				padding: 60px 20px 30px;

				.column {
					&.rank {
						top: 50%;
					}
				}
			}

			.column {
			    display: inline-block;
			    vertical-align: middle;

			    &.logo {
			    	text-align: center;

			    	a {
					    text-decoration: none;

					    img {
						    height: auto;
							margin-right: 10px;
							width: 90%;
						}
					}
			    }

			    &.rank {
			    	font-size: 16px;
			    	font-weight: bold;
			    	line-height: 30px;
			    	background-color: #CD3097;
			    	box-shadow: none;
			        border-radius: 50%;
			        width: 30px;
			        height: 30px;
			        text-align: center;
			        position: absolute;
			        top: 40%;
			        left: -15px;
			    }

			    &.text {
					color: #8B97B4;
					margin-right: 10px;
  					margin-left: 10px;

			    	.title {
			    		color: $white;
			    	}

			    	.stars {
						display: inline-block;
						letter-spacing: -2px;
						}
						.rating_number {
							display: inline;
							padding-left: 3px;
							font-weight: normal;
							font-size: 12px;
							letter-spacing: 0;
						}
			    }

			    &.bonus {

			    	.amount {
			    		display: block;
			    		font-size: 20px;
			    		font-weight: 600;
			    	}

			    }

				&.button {
					text-align: center;
					float: right;

				    .btn {
				    	color: $white;
				    	margin-bottom: 10px;
				    	padding: 5px 15px;
				    }

				    a {
							color: $pink;
							display: inline-block;
							padding-left: 20px;
				    }
				}
			}

			.column {
				&.logo {
			    	width: 15%;
			    }

			    &.text {
				    width: 37%;
				}

				&.bonus {
				    width: 23%;
				}

				&.button {
				    width: 21%;
				}
			}
		}

		.custom_shortcode{
			border: 1px solid #cd3097;
			list-style-type: none;
			margin-top: 15px;
			padding: 14px 19px;
			padding-top: 20px;
			height: auto;
			display: flow-root;

			.column {
				&.logo {
					width: 13%;
			    }

			    &.text {
					width: 62%;
					font-size: $font-size-body;
				}

				&.button {
					width: 21%;
					display: inline-block;
					vertical-align: middle;
					float: none;

					.btn{
						padding: 4px 22px;
						font-weight: normal;
						border-radius: 10px;
					}

					.read_more{
						padding-left: 6px;
					}
				}
			}
		}
	}
}
