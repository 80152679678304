@charset "UTF-8";
/* @import "bootstrap-grid";

@import "functions";
@import "mixins";
@import "utilities"; */
/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container-xl, .navbar > .container-lg, .navbar > .container-md, .navbar > .container-sm, .navbar .container,
.navbar .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container-xl, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-md, .navbar-expand-md > .container-sm, .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container-xl, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-md, .navbar-expand-md > .container-sm, .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container-xl, .navbar-expand > .container-lg, .navbar-expand > .container-md, .navbar-expand > .container-sm, .navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container-xl, .navbar-expand > .container-lg, .navbar-expand > .container-md, .navbar-expand > .container-sm, .navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
img {
    page-break-inside: avoid;
  }
  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }
  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/* Width */
/* Height */
/* Standard Colours */
/* Colour Palette */
/* Fonts */
/**
 *
 * FONT SMOOTHING
 *
 * Changes the font rendering,
 * allowing thinner fonts
 */
/**
 * MEDIA QUERIES
 * -------------
 */
/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 1.5em auto;
  text-align: center;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}
@media (max-width: 1023px) {
  .alignleft {
    display: block;
    float: none;
    margin: 0 auto;
  }
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.bypostauthor {
  display: block;
}

.gallery-caption {
  display: block;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}
.screen-reader-text:focus {
  background-color: #eee;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

.sticky {
  display: block;
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

.comment-respond {
  background-color: #0D1832;
  margin-bottom: 20px;
  padding: 20px;
}
.comment-list {
  background-color: #0D1832;
  margin-bottom: 20px;
  padding: 20px;
}
.comment-list li {
  list-style-type: none;
}

.comments input[type=text],
.comments textarea {
  background-color: #0B142A;
  border: 0;
  color: #fff;
  padding: 10px;
  -webkit-appearance: none;
}
.comments textarea {
  width: 100%;
}

.gform_wrapper .gform_button {
  background-color: #0D1832;
  border-radius: 0;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: normal;
  margin-bottom: 20px;
  padding: 20px 30px;
  text-transform: uppercase;
  width: 100%;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  .gform_wrapper .gform_button {
    margin-bottom: 30px;
  }
}
.gform_wrapper input,
.gform_wrapper textarea {
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  padding: 3px 10px;
}
.gform_wrapper .gfield_label {
  font-weight: 500 !important;
}
.gform_wrapper .gfield_radio li,
.gform_wrapper .gfield_checkbox li {
  display: inline-block;
  float: left;
  margin-right: 10px;
}
.gform_wrapper .gfield_radio li input,
.gform_wrapper .gfield_checkbox li input {
  appearance: none;
  background: #fff;
  border: 1px solid #cdcecd;
  color: #cdcecd;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  margin-right: 0.5rem;
  outline: none;
  padding: 0;
  width: 20px !important;
  vertical-align: top;
  z-index: 99;
}
.gform_wrapper .gfield_radio li label,
.gform_wrapper .gfield_checkbox li label {
  font-size: 12px;
}

.footer input[type=text],
.footer textarea {
  color: #fff;
  line-height: 20px;
  width: 100%;
}
.footer .gform_wrapper input,
.footer .gform_wrapper textarea {
  background: #1B1B1B;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 3px 10px;
}
.footer .gform_wrapper div.validation_error {
  background-color: #790000;
  color: #fff !important;
}
.footer .gform_wrapper div.validation_message {
  display: none !important;
}
.footer .gform_wrapper li.gfield.gfield_error {
  background-color: transparent !important;
  border: 0 !important;
  margin-bottom: 8px !important;
  padding: 0 !important;
}
.footer .gform_wrapper li.gfield.gfield_error.gfield_contains_required div.ginput_container {
  margin-top: 8px !important;
}
.footer .gform_wrapper li.gfield.gfield_error.gfield_contains_required label.gfield_label {
  margin-top: 0 !important;
}
.footer .gform_wrapper .validation_message {
  color: #ff0000;
  font-weight: 700;
}
.footer .gform_wrapper .gfield_label {
  color: #b0b6b6;
  display: block;
  font-size: 14px !important;
  margin-bottom: 5px;
}
.footer .gform_wrapper .gfield {
  margin-top: 0 !important;
  margin-bottom: 10px;
}
.footer .gform_wrapper .gform_button {
  background: #3c3c3c;
  border: none;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  margin-top: 30px;
  padding: 5px 20px;
  text-transform: uppercase;
  width: 100%;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  .footer .gform_wrapper .gform_button {
    margin-bottom: 30px;
  }
}
.footer .gform_wrapper .gfield_radio li,
.footer .gform_wrapper .gfield_checkbox li {
  display: inline-block;
  float: left;
  margin-right: 10px;
}
.footer .gform_wrapper .gfield_radio li input,
.footer .gform_wrapper .gfield_checkbox li input {
  appearance: none;
  background: #fff;
  border: 1px solid #cdcecd;
  color: #cdcecd;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  margin-right: 0.5rem;
  outline: none;
  padding: 0;
  width: 20px !important;
  vertical-align: top;
  z-index: 99;
}
.footer .gform_wrapper .gfield_radio li input::before,
.footer .gform_wrapper .gfield_checkbox li input::before {
  content: "";
  display: inline-block;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  vertical-align: top;
}
.footer .gform_wrapper .gfield_radio li input:checked::before,
.footer .gform_wrapper .gfield_checkbox li input:checked::before {
  content: "╳";
  display: inline-block;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  vertical-align: top;
}
.footer .gform_wrapper .gfield_radio li label,
.footer .gform_wrapper .gfield_checkbox li label {
  font-size: 12px;
}

.cards-toplist {
  background: #f5f5ff;
  overflow: hidden;
  position: relative;
}
.cards-toplist h2 {
  width: 100%;
  font-size: 23px;
  text-align: left;
  text-transform: uppercase;
}
.cards-toplist__image {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  bottom: 0;
  object-fit: cover;
}
.cards-toplist__content {
  text-align: center;
  margin-bottom: 1rem;
}
.cards-toplist__content p {
  text-align: left;
}
.cards-toplist__content p:last-of-type {
  margin-bottom: 0;
}
.cards-toplist__list {
  list-style-type: none;
  padding: 0;
  width: 100%;
  margin: 0;
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
}
@media (max-width: 767px) {
  .cards-toplist__list {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}
.cards-toplist__list > li {
  max-width: 100%;
  position: relative;
  display: grid;
  grid-template: repeat(4, auto)/repeat(4, 25%);
  margin-bottom: 10px;
  padding: 0 5px 5px 5px;
  border-top: 3px solid #5c6ca3;
  background: transparent;
}
@media (max-width: 767px) {
  .cards-toplist__list > li {
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .cards-toplist__list > li:last-of-type {
    margin-bottom: 0;
  }
}
.cards-toplist__list > li > div {
  overflow: hidden;
  padding: 10px;
  font-size: 14px;
  color: #606366;
  border-bottom: 1px solid #eee;
}
.cards-toplist__list > li > div .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cards-toplist__list .cards-toplist__cell--number {
  display: block;
  position: absolute;
  top: -10px;
  left: 0;
  border: none !important;
}
.cards-toplist__list .cards-toplist__cell--cta {
  grid-column: 1/span 4;
  grid-row: 4;
  order: 5;
  width: auto;
  padding: 10px 0 0;
}
.cards-toplist__list .cards-toplist__cell--cta, .cards-toplist__list .cards-toplist__cell--read_review, .cards-toplist__list .cards-toplist__cell--disclaimer {
  border-bottom: none;
}
.cards-toplist__list .cards-toplist__cell--read_review, .cards-toplist__list .cards-toplist__cell--disclaimer {
  grid-column: 1/span 4;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cards-toplist__list .cards-toplist__cell--disclaimer {
  align-items: flex-end;
}
.cards-toplist__list .cards-toplist__cell--logo {
  grid-column: 1;
  grid-row: 1;
  order: 0;
  display: flex;
  padding-left: 0;
}
.cards-toplist__list .cards-toplist__cell--bonus {
  grid-column: 1/span 4;
  grid-row: 2;
  padding-left: 10px !important;
}
.cards-toplist__list .cards-toplist__cell--pros {
  grid-column: 1/span 4;
  grid-row: 3;
  padding-left: 10px !important;
}
.cards-toplist__list .cards-toplist__cell--rating {
  grid-column: 2/span 2;
  grid-row: 1;
  order: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px !important;
}
.cards-toplist__list .cards-toplist__cell--license {
  grid-column: 4;
  grid-row: 1;
  order: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cards-toplist .toplist__cell-number {
  border-radius: 0;
  border-bottom-right-radius: 3px;
  background: #5c6ca3;
  color: #fff;
}
.cards-toplist .toplist__cell-cta {
  width: 100%;
}
.cards-toplist .toplist__cell-read_review {
  margin-top: 12px;
}
.cards-toplist .toplist__cell-disclaimer {
  margin-top: 10px;
  background: #eee;
  padding: 10px 20px;
  width: 100%;
}
.cards-toplist .toplist__cell-disclaimer p {
  line-height: 1;
}

.oddslist {
  background: #f5f5ff;
}
.oddslist__title {
  border-bottom: 3px solid #d1d8e8;
  margin-bottom: 20px;
  display: flex;
}
.oddslist__title h2 {
  color: #212529;
  border-bottom: 3px solid #8395c6;
  margin-bottom: -3px;
  display: inline-block;
  line-height: 1.25;
  font-size: 18px;
}
.oddslist__filter-nav {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 10px;
  white-space: nowrap;
  position: relative;
  color: #212529;
}
.oddslist__filter-menu {
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row;
  list-style: none;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1.1px;
  margin-left: 5px;
}
.oddslist__filter-menu li {
  text-decoration: none;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, segoe ui, Roboto, Ubuntu, sans-serif;
  text-transform: uppercase;
  position: relative;
  display: flex;
  margin: 0 5px;
  padding: 0 10px 0 0;
  flex-direction: column;
  letter-spacing: normal;
  color: #212529;
}
.oddslist__filter-menu li:not(:last-of-type) {
  border-right: 1px solid #d1d8e8;
}
.oddslist__filter-menu li a {
  text-decoration: none;
  color: inherit;
  margin: auto;
}
.oddslist__table {
  width: 100%;
  margin: 0;
}
.oddslist__table thead {
  background: #5c6ca3;
}
.oddslist__table thead th {
  color: #fff;
  text-align: left;
  font-size: 14px;
  padding: 10px;
}
.oddslist__table tbody td {
  overflow: hidden;
  border-bottom: 1px solid #eee;
  padding: 10px;
  font-size: 14px;
  color: #606366;
}
.oddslist__table tbody td .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.oddslist__table tbody td a {
  color: #606366;
}
.oddslist__pagination {
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.oddslist__pagination li {
  list-style-type: none;
}
.oddslist__pagination li.next {
  margin-left: auto;
}
.oddslist__pagination span,
.oddslist__pagination a {
  color: black;
}
.oddslist__pagination .current {
  font-weight: bold;
}
@media (max-width: 767.98px) {
  .oddslist__table thead th {
    display: none;
  }
  .oddslist__table tbody tr {
    max-width: 100%;
    position: relative;
    display: grid;
    grid-template: repeat(4, auto)/repeat(4, 25%);
    margin-bottom: 10px;
    padding: 5px;
    border-top: 3px solid #5c6ca3;
    padding-top: 0;
  }
  .oddslist__table tbody tr td {
    padding-left: 0;
    padding-right: 0;
  }
  .oddslist__filter-nav {
    align-items: flex-start;
  }
  .oddslist__filter-menu {
    overflow-x: scroll;
    padding-bottom: 5px;
  }
}

.poolspellist {
  background: #f5f5ff;
}
.poolspellist__title {
  border-bottom: 3px solid #d1d8e8;
  margin-bottom: 20px;
  display: flex;
}
.poolspellist__title h2 {
  color: #212529;
  border-bottom: 3px solid #8395c6;
  margin-bottom: -3px;
  display: inline-block;
  line-height: 1.25;
  font-size: 18px;
}
.poolspellist__filter-nav {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 10px;
  white-space: nowrap;
  position: relative;
  color: #212529;
}
.poolspellist__filter-menu {
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row;
  list-style: none;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1.1px;
  margin-left: 5px;
}
.poolspellist__filter-menu li {
  text-decoration: none;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, segoe ui, Roboto, Ubuntu, sans-serif;
  text-transform: uppercase;
  position: relative;
  display: flex;
  margin: 0 5px;
  padding: 0 10px 0 0;
  flex-direction: column;
  letter-spacing: normal;
  color: #212529;
}
.poolspellist__filter-menu li:not(:last-of-type) {
  border-right: 1px solid #d1d8e8;
}
.poolspellist__filter-menu li a {
  text-decoration: none;
  color: inherit;
  margin: auto;
}
.poolspellist__table {
  width: 100%;
  margin: 0;
}
.poolspellist__table thead {
  background: #5c6ca3;
}
.poolspellist__table thead th {
  color: #fff;
  text-align: left;
  font-size: 14px;
  padding: 10px;
}
.poolspellist__table tbody td {
  overflow: hidden;
  border-bottom: 1px solid #eee;
  padding: 10px;
  font-size: 14px;
  color: #606366;
}
.poolspellist__table tbody td .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.poolspellist__table tbody td a {
  color: #606366;
}
.poolspellist__pagination {
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.poolspellist__pagination li {
  list-style-type: none;
}
.poolspellist__pagination li.next {
  margin-left: auto;
}
.poolspellist__pagination span, .poolspellist__pagination a {
  color: black;
}
.poolspellist__pagination .current {
  font-weight: bold;
}
@media (max-width: 767.98px) {
  .poolspellist__table thead th {
    display: none;
  }
  .poolspellist__table tbody tr {
    max-width: 100%;
    position: relative;
    display: grid;
    grid-template: repeat(4, auto)/repeat(4, 25%);
    margin-bottom: 10px;
    padding: 5px;
    border-top: 3px solid #5c6ca3;
    padding-top: 0;
  }
  .poolspellist__table tbody tr td {
    padding-left: 0;
    padding-right: 0;
  }
  .poolspellist__filter-nav {
    align-items: flex-start;
  }
  .poolspellist__filter-menu {
    overflow-x: scroll;
    padding-bottom: 5px;
  }
}

.speltipslist {
  background: #f5f5ff;
}
.speltipslist__title {
  border-bottom: 3px solid #d1d8e8;
  margin-bottom: 20px;
  display: flex;
}
.speltipslist__title h2 {
  color: #212529;
  border-bottom: 3px solid #8395c6;
  margin-bottom: -3px;
  display: inline-block;
  line-height: 1.25;
  font-size: 18px;
}
.speltipslist__filter-nav {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 10px;
  white-space: nowrap;
  position: relative;
  color: #212529;
}
.speltipslist__filter-menu {
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row;
  list-style: none;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1.1px;
  margin-left: 5px;
}
.speltipslist__filter-menu li {
  text-decoration: none;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, segoe ui, Roboto, Ubuntu, sans-serif;
  text-transform: uppercase;
  position: relative;
  display: flex;
  margin: 0 5px;
  padding: 0 10px 0 0;
  flex-direction: column;
  letter-spacing: normal;
  color: #212529;
}
.speltipslist__filter-menu li:not(:last-of-type) {
  border-right: 1px solid #d1d8e8;
}
.speltipslist__filter-menu li a {
  text-decoration: none;
  color: inherit;
  margin: auto;
}
.speltipslist__table {
  width: 100%;
  margin: 0;
}
.speltipslist__table thead {
  background: #5c6ca3;
}
.speltipslist__table thead th {
  color: #fff;
  text-align: left;
  font-size: 14px;
  padding: 10px;
}
.speltipslist__table tbody td {
  overflow: hidden;
  border-bottom: 1px solid #eee;
  padding: 10px;
  font-size: 14px;
  color: #606366;
}
.speltipslist__table tbody td .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.speltipslist__table tbody td a {
  color: #606366;
}
.speltipslist__pagination {
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.speltipslist__pagination li {
  list-style-type: none;
}
.speltipslist__pagination li.next {
  margin-left: auto;
}
.speltipslist__pagination span, .speltipslist__pagination a {
  color: black;
}
.speltipslist__pagination .current {
  font-weight: bold;
}
@media (max-width: 767.98px) {
  .speltipslist__table thead th {
    display: none;
  }
  .speltipslist__table tbody tr {
    max-width: 100%;
    position: relative;
    display: grid;
    grid-template: repeat(4, auto)/repeat(4, 25%);
    margin-bottom: 10px;
    padding: 5px;
    border-top: 3px solid #5c6ca3;
    padding-top: 0;
  }
  .speltipslist__table tbody tr td {
    padding-left: 0;
    padding-right: 0;
  }
  .speltipslist__filter-nav {
    align-items: flex-start;
  }
  .speltipslist__filter-menu {
    overflow-x: scroll;
    padding-bottom: 5px;
  }
}

.table__container {
  border-bottom: 1px solid #4F6CA9;
  margin-bottom: 13px;
}
@media (max-width: 767.98px) {
  .table__container {
    border-bottom: none;
  }
}
.table__container th {
  font-size: 15px !important;
}
.table__container tr:nth-child(2n) {
  background: rgba(79, 108, 169, 0.1);
}
.table__container td {
  font-size: 12px !important;
  line-height: 18px !important;
  border-bottom: none !important;
}
.table__container td,
.table__container td a {
  color: #585C5F !important;
}
.table__container td[class$=__col-game_stop] {
  display: flex;
  flex-direction: column;
}
.table__container td[class$=__col-game_stop] .date {
  color: #4F6CA9 !important;
}
.table__container tbody td a {
  display: flex;
  align-items: center;
}
.table__container tbody td a > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table__container td[class$=__col-title] > span {
  display: flex;
  flex-direction: column;
}
@media (max-width: 767.98px) {
  .table__container td[class$=__col-title] > span > span {
    margin-top: 3px;
  }
}
.table__container td[class$=__col-title] a {
  color: #4F6CA9 !important;
}
.table__container td[class$=__col-author] {
  white-space: nowrap;
}
.table__container td[class$=__col-author] img {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  object-fit: cover;
  margin-right: 10px;
}
.table__container td[class$=__col-odds] span {
  color: #fafafa;
  background-color: #4F6CA9;
  padding: 3px 9px;
  border-radius: 12px;
  white-space: nowrap;
}
@media (max-width: 767.98px) {
  .table__container thead tr {
    display: flex;
  }
  .table__container thead tr th[class$=__header-game_stop] {
    width: 50%;
  }
  .table__container thead tr th[class$=__header-author] {
    padding-left: 0;
  }
  .table__container thead tr th[class$=__header-author],
.table__container thead tr th[class$=__header-game_stop] {
    display: block;
  }
  .table__container tbody tr {
    position: relative;
    display: grid;
    margin: 0;
    grid-template: repeat(4, auto)/repeat(2, 50%);
    padding: 0 0 14px;
    border: none !important;
  }
  .table__container tbody tr td {
    font-size: 18px !important;
  }
  .table__container tbody tr td[class$=__col-game_stop] {
    grid-column: 1;
    grid-row: 1;
    padding: 14px 0 14px 8px;
  }
  .table__container tbody tr td[class$=__col-game_stop] .time {
    color: #a3a8ac !important;
    margin-top: 3px;
  }
  .table__container tbody tr td[class$=__col-author] {
    grid-column: 2;
    grid-row: 1;
    padding: 14px 8px 14px 0;
  }
  .table__container tbody tr td[class$=__col-author] a img {
    width: 40px;
    height: 40px;
  }
  .table__container tbody tr td[class$=__col-author] a span {
    font-size: 14px;
  }
  .table__container tbody tr td[class$=__col-game_stop],
.table__container tbody tr td[class$=__col-author] {
    position: relative;
  }
  .table__container tbody tr td[class$=__col-game_stop]::before,
.table__container tbody tr td[class$=__col-author]::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid #f2f2f2;
  }
  .table__container tbody tr td[class$=__col-title] {
    grid-column: 1/span 2;
    grid-row: 2;
    padding: 14px 8px 10px 8px;
  }
  .table__container tbody tr td[class$=__col-game_type] {
    grid-column: 1/span 2;
    grid-row: 3;
    padding: 0 8px 10px 8px;
  }
  .table__container tbody tr td[class$=__col-casino] {
    padding: 0 0 0 8px;
    grid-column: 1;
    grid-row: 4;
  }
  .table__container tbody tr td[class$=__col-odds] {
    padding: 0 8px 0 0;
    text-align: right;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    grid-column: 2;
    grid-row: 4;
  }
  .table__container tbody tr td[class$=__col-odds] span {
    padding: 5px 12px;
  }
  .table__container tbody tr td[class$=__col-title]::before,
.table__container tbody tr td[class$=__col-casino]::before,
.table__container tbody tr td[class$=__col-game_type]::before {
    font-size: 14px;
    margin-bottom: 2px;
    content: attr(data-title);
    display: block;
    color: #979797;
  }
  .table__container tbody tr:nth-of-type(2n) td[class$=__col-game_stop]::before,
.table__container tbody tr:nth-of-type(2n) td[class$=__col-author]::before {
    border-color: white;
  }
  .table__container tbody tr td[class$=__col-author],
.table__container tbody tr td[class$=__col-odds] {
    padding-right: 16px;
  }
  .table__container tbody tr td[class$=__col-casino],
.table__container tbody tr td[class$=__col-game_stop] {
    padding-left: 16px;
  }
  .table__container tbody tr td[class$=__col-title],
.table__container tbody tr td[class$=__col-game_type] {
    padding-right: 16px;
    padding-left: 16px;
  }
}

@media (max-width: 767.98px) {
  body.author .table__container tbody tr td[class$=__col-game_stop] {
    grid-column: 1/span 2;
  }
}

.oddslist__pagination .prev a,
.oddslist__pagination .next a,
.poolspellist__pagination .prev a,
.poolspellist__pagination .next a,
.speltipslist__pagination .prev a,
.speltipslist__pagination .next a {
  display: flex;
  align-items: center;
  color: #365596;
  font-style: italic;
  font-size: 12px;
  text-decoration: none !important;
}
.oddslist__pagination .next a,
.poolspellist__pagination .next a,
.speltipslist__pagination .next a {
  margin-right: 30px;
}
.oddslist__pagination .next a::after,
.poolspellist__pagination .next a::after,
.speltipslist__pagination .next a::after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='18' viewBox='0 0 24 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.2868 7.5124H1.33333C0.596954 7.5124 0 8.17842 0 9C0 9.82158 0.596954 10.4876 1.33333 10.4876H19.2868L14.9434 15.3335C14.3967 15.9435 14.3967 16.9325 14.9434 17.5425C15.4901 18.1525 16.3765 18.1525 16.9233 17.5425L23.5899 10.1045C24.1367 9.4945 24.1367 8.5055 23.5899 7.89551L16.9233 0.457494C16.3765 -0.152498 15.4901 -0.152498 14.9434 0.457494C14.3967 1.06749 14.3967 2.05648 14.9434 2.66647L19.2868 7.5124Z' fill='%23365596'/%3E%3C/svg%3E%0A");
  background-size: 100%;
  width: 12px;
  height: 9px;
  display: block;
  margin-left: 10px;
}
.oddslist__pagination .prev a,
.poolspellist__pagination .prev a,
.speltipslist__pagination .prev a {
  margin-left: 30px;
}
.oddslist__pagination .prev a::before,
.poolspellist__pagination .prev a::before,
.speltipslist__pagination .prev a::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='18' viewBox='0 0 24 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.71323 7.5124H22.6667C23.403 7.5124 24 8.17842 24 9C24 9.82158 23.403 10.4876 22.6667 10.4876H4.71323L9.05662 15.3335C9.60335 15.9435 9.60335 16.9325 9.05662 17.5425C8.50988 18.1525 7.62345 18.1525 7.07672 17.5425L0.410051 10.1045C-0.136684 9.4945 -0.136684 8.5055 0.410051 7.89551L7.07672 0.457494C7.62345 -0.152498 8.50988 -0.152498 9.05662 0.457494C9.60335 1.06749 9.60335 2.05648 9.05662 2.66647L4.71323 7.5124Z' fill='%23365596'/%3E%3C/svg%3E%0A");
  background-size: 100%;
  width: 12px;
  height: 9px;
  display: block;
  margin-right: 10px;
}

.author-load-more__container {
  display: flex;
  justify-content: center;
  padding-top: 17px;
}
.author-load-more__container .btn-load-more {
  color: #fafafa;
  background-color: #4F6CA9;
  border-radius: 20px;
  padding: 6px 68px;
  font-style: italic;
  text-transform: none;
  border: none;
  width: auto !important;
}

@keyframes placeholder {
  0% {
    background-position: -600px 0;
  }
  100% {
    background-position: 600px 0;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -56;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -149;
  }
}
.fb-customerchat-button {
  background: none;
  border-radius: 60px;
  bottom: 16px;
  display: block;
  height: 36px;
  margin: 0px 12px;
  overflow: visible;
  padding: 0px;
  position: fixed;
  right: 12px;
  top: auto;
  width: 99px;
  z-index: 214748364444;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px 0px;
  cursor: pointer;
}
.fb-customerchat-button > div {
  background-color: rgb(245, 166, 35);
  align-items: center;
  border-radius: 60px;
  display: flex;
  height: 36px;
  padding: 0px 14px;
  position: absolute;
  width: fit-content;
  right: 0px;
  width: 100%;
}
.fb-customerchat-button > div > div {
  margin-left: -2px;
  margin-right: 4px;
}
.fb-customerchat-button > div > div > div {
  display: flex;
  align-items: center;
}
.fb-customerchat-button .loader {
  margin-right: 4px;
  margin-left: -2px;
  display: none;
}
.fb-customerchat-button .loader .spinning {
  animation: spin 2s linear infinite;
}
.fb-customerchat-button .loader .spinning .path {
  animation: dash 1.5s ease-in-out infinite;
  stroke: white;
  stroke-linecap: round;
}
.fb-customerchat-button.loading .loader {
  display: block;
}
.fb-customerchat-button.loading .loader + div {
  display: none;
}
.fb-customerchat-button span {
  color: white;
  display: flex;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  user-select: none;
  white-space: nowrap;
}

.faq {
  background-size: cover;
  background-position: center;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.faq h2 {
  width: 100%;
  font-size: 23px;
  text-align: center;
  text-transform: uppercase;
}
.faq__content {
  text-align: center;
  margin-bottom: 1rem;
}
.faq__content p:last-of-type {
  margin-bottom: 0;
}
.faq__image {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}
.faq__item {
  flex: 1;
  background: #fbfbfb;
  padding: 5px 20px;
  margin-bottom: 4px;
  list-style-type: none;
}
.faq__item:hover {
  background-color: #ffead5;
  cursor: pointer;
}
.faq__item h3 {
  font-size: 18px;
  padding: 12px 3px 10px 0;
  margin: 0;
  display: flex;
  align-items: center;
}
.faq__item h3 span {
  flex-grow: 1;
}
.faq__item h3 .fas::before {
  content: "\f078";
}
.faq__item .faq__item-content {
  overflow: hidden;
  transition: height 0.25s ease;
}
.faq__item .faq__item-content a {
  color: #ef4136;
  border-bottom: 2px solid #ef4136;
}
.faq__item .faq__item-content a:hover {
  border-bottom: 0;
  text-decoration: none;
}
.faq__item .faq__item-content ul {
  margin: 4px;
  padding-left: 10px;
}
.faq__item .faq__item-content ul li {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
  line-height: 1.7em;
  list-style: none;
  list-style: outside;
  list-style-type: none;
  list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjdGQ0IyMjM3Mzk3QjExRTc5N0UxQjI0RENGQjRCQkQyIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjdGQ0IyMjM4Mzk3QjExRTc5N0UxQjI0RENGQjRCQkQyIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6N0ZDQjIyMzUzOTdCMTFFNzk3RTFCMjREQ0ZCNEJCRDIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6N0ZDQjIyMzYzOTdCMTFFNzk3RTFCMjREQ0ZCNEJCRDIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7DZf0BAAABPUlEQVR42mL8//8/A6mABZnzfPEyhs/nzsUxsbG/AnJ3gMT+//7NwKmsxCCTnYFQCLIJjP/+ZbwYGJa7j5P//0FhqR9A7AHEDPu5BBjOu3kj1AEx3KZ/P36y/nn/IYVVUJCBkY2NHSi0FoiDGVmYdzBxcaE4jwnO4OL8xaWqEvjv9+8bUCEuqEYPBkZG7JqgTr0HVsTAcBlF479/3jg1QcFDIE4A4l9gBaysXN8fPlz8+/VrAXyahIG4H4jZwH798+cfh4xMIauw8EesQc7IzCwKpDYBsQUsfIBujmdkZV3CwMSE6bx/33+wfrtzdwMTCwtCAwNDPBAvYUBLAHCbgKb9Zebm2vPn82crpj9/GWAa/n3/BoyOHzg0sTD/E3Z1rmfm4vzCxMH5AmwDKER//WTg0tRA0cRITtoDCDAA7LV4PVdALkEAAAAASUVORK5CYII=);
}
.faq__item * {
  line-height: 1.7em;
  color: rgba(0, 0, 0, 0.7);
}
.faq__item:not(.opened) .faq__item-content {
  display: none;
}
.faq__item.opened .faq__item-content {
  display: block;
}
.faq__item.opened h3 {
  color: #ef4136;
}
.faq__item.opened h3 .fas::before {
  content: "\f077";
}

.latest-news {
  background-color: #0D1832;
  margin-top: 20px;
  padding-top: 25px;
  padding-bottom: 40px;
}
.latest-news .title {
  font-size: 20px;
}
.latest-news .seeall {
  text-transform: uppercase;
}
.latest-news .item {
  overflow: hidden;
}
.latest-news .item .column.image {
  position: relative;
}
.latest-news .item .column.body {
  background-color: #1E2D51;
  color: #8B97B4;
  padding: 20px;
  min-height: 100px;
}
.latest-news .item .column.body .info {
  margin-bottom: 5px;
}
@media (max-width: 1023px) {
  .latest-news .item {
    margin-bottom: 20px;
  }
}

.section-table table {
  display: block;
}
.section-table thead {
  display: block;
}
.section-table tr.sort {
  background: #E4882E;
  display: block;
  margin-bottom: 10px;
  overflow: hidden;
}
.section-table tr.sort th {
  display: block;
  float: left;
  text-align: left;
  color: #fff;
  font-size: 13px;
  background-size: 10px;
  line-height: 32px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.section-table tr.item {
  display: block;
  background: #fbfbfb;
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
  overflow: hidden;
  padding: 8px 0;
  width: 100%;
}
.section-table tr.item.item-1, .section-table tr.item.item-2, .section-table tr.item.item-3 {
  padding: 12px 0;
}
.section-table tr.item:hover {
  background: #efefef;
}
.section-table tr.item p {
  margin-bottom: 0;
  color: #000;
  font-size: 14px;
  line-height: 35px;
}
.section-table tr.item p span.mobile-text {
  display: none;
}
.section-table tr.item td.column {
  display: block;
  float: left;
  min-height: 1px;
}
.section-table tr.item td.column.button {
  text-align: center;
  padding-right: 10px;
}
.section-table tr.item td.column.count {
  text-align: center;
}
.section-table tr.item td.column.count span {
  display: block;
  font-size: 18px;
  color: #ccc;
  margin: auto;
  line-height: 35px;
}
.section-table tr.item td.column.logo a {
  text-decoration: none;
}
.section-table tr.item td.column.logo a img {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  vertical-align: middle;
  margin-right: 5px;
}
.section-table tr.item td.column.logo a h4 {
  display: inline-block;
  font-weight: 600;
  color: #E4882E;
  font-size: 16px;
  vertical-align: middle;
}
.section-table tr.item td.column.bonus-text p {
  font-size: 20px;
  color: #000;
  line-height: 24px;
  padding: 4px 0;
}
.section-table tr.item td.column.bonus-text p i {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}
.section-table tr.item td.column.turnover strong {
  font-size: 20px;
}
.section-table .count {
  width: 50px;
}
.section-table .logo {
  width: 180px;
}
.section-table .bonus-text {
  width: 240px;
}
.section-table .bonus-code {
  width: 150px;
}
.section-table .turnover {
  width: 200px;
}
.section-table .free-spins {
  width: 150px;
}
.section-table .button {
  width: 170px;
}
.section-table .read-more {
  text-align: right;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .section-table .count {
    width: 10%;
  }
  .section-table .bonus-text {
    width: calc(90% - 105px);
  }
  .section-table .turnover {
    width: calc(90% - 105px);
  }
  .section-table .free-spins {
    width: calc(90% - 105px);
  }
  .section-table .button {
    width: 105px;
  }
  .section-table tr.sort th span {
    font-size: 12px;
  }
  .section-table tr.item {
    position: relative;
  }
  .section-table tr.item td.column {
    margin-left: 10%;
    display: block;
    float: left;
  }
  .section-table tr.item td.column.button {
    margin-left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -17px;
  }
  .section-table tr.item td.column.button a.btn {
    font-size: 10px;
  }
  .section-table tr.item td.column.count {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -8px;
    margin-left: 0;
  }
  .section-table tr.item td.column.count span {
    display: block;
  }
  .section-table tr.item td.column.count.logo {
    margin-bottom: 6px;
  }
  .section-table tr.item td.column.count.bonus-text {
    margin-bottom: 2px;
  }
  .section-table tr.item td.column.count.bonus-text p {
    font-size: 20px;
    line-height: 1.05em;
  }
  .section-table tr.item td.column.count.bonus-text p i {
    font-size: 14px;
  }
  .section-table tr.item td.column.turnover strong {
    font-size: 14px;
  }
  .section-table tr.item p {
    font-size: 11px;
    line-height: 1.4em;
  }
  .section-table tr.item p span.mobile-text {
    display: inline;
    padding-right: 3px;
  }
}
.section-table-mini {
  overflow: hidden;
}
.section-table-mini .box ol {
  margin: 0;
  padding: 0;
}
.section-table-mini .box .item {
  background: #fbfbfb;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 3px;
  overflow: hidden;
  width: 100%;
}
.section-table-mini .box .item .column {
  float: left;
}
.section-table-mini .box .item .column.logo img {
  border-radius: 4px;
  margin-right: 7px;
}
.section-table-mini .box .item .column.bonus-text h4 {
  color: #E4882E;
  font-size: 16px;
  margin-bottom: 1px;
}
.section-table-mini .box .item .column.bonus-text p {
  color: #231f20;
  font-size: 16px;
  line-height: 1.2em;
  margin-bottom: 0;
}
.section-table-mini .box .item .column.bonus-text p i {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.section-table-mini .box .item .column.button a {
  font-size: 12px;
}
.section-table-mini .logo {
  width: 35px;
}
.section-table-mini .bonus-text {
  width: calc(100% - 160px);
  padding: 0 12px;
}
.section-table-mini .button {
  width: 125px;
}
.section-table-mini .read-more {
  float: right;
}

.section-table-no-deposit .list__list .item .column.bonus {
  width: 63%;
}

.two-lists {
  overflow: hidden;
}
.two-lists h2 {
  background-size: 10px;
  color: #fff;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 32px;
  margin-bottom: 10px;
  overflow: hidden;
  padding: 0;
  padding-left: 15px;
  text-transform: uppercase;
}
.two-lists ol {
  counter-reset: item;
  margin: 0;
  padding: 0;
}
.two-lists li.item {
  overflow: hidden;
  margin-bottom: 5px;
  padding: 8px 0;
  border-bottom: 1px solid #2a334b;
  counter-increment: item;
}
.two-lists li.item:before {
  content: counter(item);
  font-size: 13px;
  color: #ccc;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  width: 5%;
}
.two-lists li.item .column {
  display: inline-block;
  vertical-align: middle;
}
.two-lists li.item .column.logo {
  text-align: center;
}
.two-lists li.item .column.logo a {
  text-decoration: none;
}
.two-lists li.item .column.logo a img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  vertical-align: middle;
  margin-right: 5px;
}
.two-lists li.item .column.logo a h4 {
  display: inline-block;
  font-weight: 600;
  color: #E4882E;
  font-size: 16px;
  vertical-align: middle;
}
.two-lists li.item .column.text {
  color: #8B97B4;
  width: 60%;
}
.two-lists li.item .column.text .title {
  color: #fff;
}
.two-lists li.item .column.button {
  padding-right: 10px;
  float: none;
  width: 20%;
}
.two-lists .column.logo {
  width: 12%;
}
.two-lists .column.text {
  width: 45%;
}
.two-lists .column.button {
  width: 18%;
}
.two-lists .more {
  text-align: center;
  padding-top: 10px;
}
.two-lists .more a {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@media (max-width: 767px) {
  .section-table-small li.item:before {
    width: 4%;
  }
  .section-table-small li.item .column.logo {
    text-align: center;
    padding-right: 5px;
  }
  .section-table-small li.item .column.logo a h4 {
    margin-top: 2px;
    font-size: 11px;
    display: block;
  }
  .section-table-small li.item .column.bonus-text {
    width: 48%;
  }
  .section-table-small li.item .column.bonus-text p {
    font-size: 15px;
    line-height: 20px;
  }
  .section-table-small li.item .column.button a.btn {
    font-size: 12px;
  }
}
.three-casinos__container {
  background-position: center;
  background-size: cover;
  height: 188px;
  padding: 25px;
}
.three-casinos__logo {
  display: flex;
  height: 95px;
  margin-bottom: 5px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.three-casinos__logo img {
  max-height: 95px;
}
.three-casinos__button {
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-align: center;
  width: 47%;
}
.three-casinos__button.transparent {
  background-color: transparent;
  border: 1px solid #fff;
}
.three-casinos__button:last-child {
  float: right;
  margin-right: 13px;
}

.videos {
  padding: 60px 0;
}

section.section.filtered-post-grid .card {
  background-color: #f5f5f5;
  border-radius: 0;
}
section.section.filtered-post-grid .card.card-btop {
  border-top: 5px solid #e14862;
}
section.section.filtered-post-grid .card > .list-group {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
section.section.filtered-post-grid .card-title {
  font-size: 12px;
  margin: 0;
}
section.section.filtered-post-grid .list-group-item {
  background-color: transparent;
}
section.section.filtered-post-grid .list-group-item.active {
  background-color: rgba(225, 72, 98, 0.3411764706);
  border-color: #e14862;
  color: #000;
}
section.section.filtered-post-grid .filter-info {
  text-align: center;
  line-height: 1.2;
  padding: 20px;
  border-radius: 3px;
}
section.section.filtered-post-grid .filter-info .post-count {
  font-size: 28px;
  font-weight: 900;
}
section.section.filtered-post-grid .filter-info.filtered .btn {
  display: block;
}
section.section.filtered-post-grid .filter-info .btn {
  display: none;
  border-radius: 3px;
  padding: 10px 10px;
}
section.section.filtered-post-grid .filter-info-mobile {
  display: none;
}
section.section.filtered-post-grid .filter-info-mobile a {
  font-size: 12px;
}
section.section.filtered-post-grid .post-card {
  border: none;
  /* .card-details,
  .card-details span,
  .card-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  } */
}
section.section.filtered-post-grid .post-card a {
  text-decoration: none;
}
section.section.filtered-post-grid .post-card .post-card-top {
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  background: #d1d8e8;
}
section.section.filtered-post-grid .post-card .hover-info {
  display: flex;
  flex-direction: column;
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 0.3s ease;
  align-items: center;
  padding: 10px;
  background: rgba(73, 90, 147, 0.85);
  z-index: 10;
}
section.section.filtered-post-grid .post-card .hover-info .btn {
  margin: auto;
  margin-bottom: 0;
}
section.section.filtered-post-grid .post-card .card-body {
  padding: 10px 0 5px;
}
section.section.filtered-post-grid .post-card .card-details {
  margin: 0;
  font-size: 12px;
}
section.section.filtered-post-grid .post-card .card-title {
  font-size: 14px;
  margin: 0;
}
section.section.filtered-post-grid .input-group-text {
  background-color: transparent;
}
section.section.filtered-post-grid .navigator-header > .col > .input-group {
  background-color: #F5F5F5;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 16px;
}

.game-provider-list a {
  cursor: pointer;
}
.game-provider-list a:hover {
  color: #000000;
  text-decoration: none;
  background-color: #ea96a4;
}

.modal .game-provider-list.list-group {
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}
.modal .game-provider-list.list-group.list-group-flush {
  /* .list-group-item:last-child {
        border-bottom-width: 1px;
      } */
}
.modal .game-provider-list.list-group .list-group-item {
  flex: 0 0 50%;
  width: 50%;
  border-left: 0;
  /* .description {
        font-size: 10px;
      } */
}
.modal .game-provider-list.list-group .list-group-item:nth-child(2n+1) {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}
.modal .game-provider-list.list-group .list-group-item:nth-child(3n+2) {
  /* border-left: 0;
      border-right: 0; */
}
.modal .game-provider-list.list-group .list-group-item:nth-last-child(2) {
  border-bottom-width: 0;
}

.svg-inline--fa {
  width: 0.5em;
}

.announcement {
  overflow: hidden;
  position: relative;
}
.announcement > * {
  z-index: 2;
  position: relative;
}
.announcement .row {
  justify-content: center;
}
.announcement p {
  color: black;
  text-align: center;
  margin: 0;
}
.announcement__image {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}

.countdown-timer {
  background: #D6DBE5;
}
.countdown-timer .countdown-timer__items {
  overflow-x: scroll;
}
@media (max-width: 767.98px) {
  .countdown-timer .countdown-timer__items {
    overflow-x: hidden !important;
  }
}
@media (min-width: 1200px) {
  .countdown-timer--count-1 .countdown-timer__items, .countdown-timer--count-2 .countdown-timer__items {
    overflow-x: hidden;
  }
}
.countdown-timer--count-1 .countdown-timer__items {
  overflow-x: hidden !important;
}
.countdown-timer__inner {
  display: flex;
  justify-content: center;
  padding: 50px 0;
  align-items: center;
}
@media (max-width: 767.98px) {
  .countdown-timer__inner {
    flex-direction: column;
    padding: 40px 0 60px 0;
  }
}
@media (min-width: 768px) {
  .countdown-timer__inner {
    padding: 44px 0 51px 0;
  }
}
@media (min-width: 992px) {
  .countdown-timer__inner {
    padding: 50px 0;
  }
}
.countdown-timer__inner h3 {
  color: #294377;
  font-size: 40px;
  line-height: 47px;
  width: 100%;
  text-align: center;
  margin: 0 0 30px;
}
@media (min-width: 768px) {
  .countdown-timer__inner h3 {
    margin: 0 20px 0 0;
    max-width: 295px;
  }
}
@media (min-width: 992px) {
  .countdown-timer__inner h3 {
    margin: 0 80px 0 0;
  }
}
@media (min-width: 768px) {
  .countdown-timer--count-1 .countdown-timer__inner h3 {
    margin: 0 50px 0 0;
  }
}
@media (min-width: 992px) {
  .countdown-timer--count-1 .countdown-timer__inner h3 {
    margin: 0 50px 0 0;
  }
}
.countdown-timer__items {
  display: flex;
  overflow-x: scroll;
}
@media (max-width: 767.98px) {
  .countdown-timer__items {
    flex-direction: column;
    overflow-x: hidden;
  }
}
.countdown-timer__items > div {
  max-width: 340px;
}
.countdown-timer__items > div:not(:last-of-type) {
  margin: 0 0 30px;
}
@media (min-width: 768px) {
  .countdown-timer__items > div:not(:last-of-type) {
    margin: 0 30px 0 0;
  }
}
@media (min-width: 992px) {
  .countdown-timer__items > div:not(:last-of-type) {
    margin: 0 80px 0 0;
  }
}
.countdown-timer__items > div a:hover {
  text-decoration: none;
  color: #fff;
}
.countdown-timer__item {
  display: flex;
  padding: 10px 15px;
  color: #fff;
  border-radius: 5px;
  height: 100%;
  align-items: center;
  background-color: #21407D;
}
.countdown-timer__item strong {
  font-size: 14px;
  font-weight: normal;
}
.countdown-timer__item--days .time-part__colon.minute {
  display: none;
}
.countdown-timer__item--days .time-part.second {
  display: none;
}
.countdown-timer__item--hours .day {
  display: none;
}
.countdown-timer__item .time-part {
  width: 58px;
  text-align: center;
}
.countdown-timer__item .time-part::after {
  content: attr(aria-label);
  display: block;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
}
.countdown-timer__item .time-part span {
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
}
.countdown-timer__item .time-part__colon {
  margin-top: -2px;
  width: 14px;
  text-align: center;
  font-size: 40px !important;
}
.countdown-timer__item .time-part__list {
  display: flex;
}
.countdown-timer__item .time-part__list > div {
  font-style: normal;
  font-size: 50px;
  line-height: 52px;
}
.countdown-timer__item-left {
  min-width: 80px;
  width: 80px;
  height: 80px;
  max-height: 80px;
  border-radius: 5px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.countdown-timer__item-right {
  display: flex;
  flex-direction: column;
}
.countdown-timer__item-right strong {
  line-height: 16px;
}

.archive .filter {
  margin-bottom: 30px;
}
.archive .filter__button {
  border: 1px solid #1E2D51;
  border-radius: 7px;
  cursor: pointer;
  font-size: 14px;
  height: 50px;
  letter-spacing: 1.3px;
  line-height: 50px;
  text-align: center;
}
.archive .filter__button img {
  position: relative;
  bottom: 2px;
}
.archive .item {
  overflow: hidden;
  padding-bottom: 20px;
}
.archive .item .column.image {
  background-position: center;
  background-size: cover;
  height: 240px;
  position: relative;
}
.archive .item .column.image a {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
}
.archive .item .column.body {
  background-color: #1E2D51;
  color: #8B97B4;
  padding: 20px;
}
.archive .item .column.body .info {
  margin-bottom: 5px;
}
@media (max-width: 1023px) {
  .archive .item {
    margin-bottom: 20px;
  }
}

.casino-review {
  color: #fff;
}
.casino-review__container {
  padding: 38px 25px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
}
.casino-review__container > img {
  width: 100%;
  position: absolute;
  left: 0;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  object-fit: cover;
}
.casino-review__row {
  display: -webkit-box;
  flex-flow: row wrap;
}
.casino-review__row > .col p {
  margin: 0;
  font-size: 12px;
}
.casino-review__row > .col a {
  color: #fff;
  text-decoration: underline;
}
.casino-review h1 {
  color: #fff;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 22px;
  font-weight: 500;
}
.casino-review h2 {
  color: #fff;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 22px;
  font-weight: 600;
}
.casino-review .rating {
  margin-bottom: 20px;
}
.casino-review .stars {
  display: inline-block;
}
.casino-review .rating_number {
  display: inline;
  padding-left: 3px;
}
.casino-review__logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.casino-review__button {
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  text-align: center;
  width: 200px;
  float: left;
}
.casino-review__button.transparent {
  background-color: transparent;
  border: 1px solid #fff;
}

.casino-proscons {
  padding-top: 0;
}
.casino-proscons ul,
.casino-proscons li {
  list-style-type: none;
  padding: 0;
}
.casino-proscons__pros li:before {
  color: #30CD90;
  content: "+";
  display: inline-block;
  width: 10px;
}
.casino-proscons__pros li .pros_cons {
  padding-left: 10px;
  display: inline-table;
  width: 96%;
}
.casino-proscons__cons li:before {
  color: #CD3097;
  content: "-";
  display: inline-block;
  width: 10px;
}
.casino-proscons__cons li .pros_cons {
  padding-left: 10px;
  display: inline-table;
  width: 96%;
}

.casino-bonus__box {
  padding: 20px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.casino-bonus__box .btn {
  border: none;
  margin-bottom: 0;
  float: none;
  width: 130px;
}
.casino-bonus__box .title {
  font-weight: bold;
  margin: 10px 0 5px;
}
.casino-bonus__box .subtitle {
  font-size: 31px;
  font-weight: bold;
  margin-bottom: 20px;
}
.casino-bonus__box.pink {
  border: 1px solid #CD3097;
}
.casino-bonus__box.pink .btn {
  background-color: #CD3097;
}
.casino-bonus__box.purple {
  border: 1px solid #663399;
}
.casino-bonus__box.purple .btn {
  background-color: #663399;
}
.casino-bonus__box.orange {
  border: 1px solid #E4882E;
}
.casino-bonus__box.orange .btn {
  background-color: #E4882E;
}

.casino-features {
  border: 1px solid #142346;
  margin: 10px 0;
  padding: 25px;
}
.casino-features ul,
.casino-features li {
  list-style-type: none;
  padding: 0;
}
.casino-features h3 {
  font-size: 20px;
}
.casino-features li .left {
  display: inline-block;
  float: left;
  width: 30%;
}
.casino-features li .right {
  display: inline-block;
  float: left;
  width: 70%;
}
.casino-features__list {
  margin-bottom: 20px;
}
.casino-features__list li {
  padding-bottom: 20px;
  zoom: 1;
}
.casino-features__list li:before, .casino-features__list li:after {
  content: " ";
  display: table;
}
.casino-features__list li:after {
  clear: both;
}

.sidebar-list {
  background-color: #142346;
  padding: 20px 18px;
  margin-bottom: 17px;
}
.sidebar-list h3 {
  font-size: 16px;
}
.sidebar-list ul {
  padding: 0;
}
.sidebar-list li.item {
  list-style-type: none;
  overflow: hidden;
  margin-bottom: 5px;
  padding: 5px 0;
  padding-bottom: 9px;
  border-bottom: 1px solid #2a334b;
}
.sidebar-list li.item:last-child {
  border-bottom: none;
}
.sidebar-list li.item .item-disclaimer {
  margin-top: 5px;
}
.sidebar-list li.item .item-disclaimer p {
  font-size: 10px;
  margin: 0;
  line-height: 1.3;
  color: #b4b4b4;
}
.sidebar-list li.item .item-disclaimer a {
  text-decoration: underline;
  color: #b4b4b4;
}
.sidebar-list li.item .column {
  display: inline-block;
  vertical-align: middle;
}
.sidebar-list li.item .column.logo {
  text-align: center;
}
.sidebar-list li.item .column.logo a {
  text-decoration: none;
}
.sidebar-list li.item .column.logo a img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  vertical-align: middle;
  margin-right: 5px;
}
.sidebar-list li.item .column.logo a h4 {
  display: inline-block;
  font-weight: 600;
  color: #E4882E;
  font-size: 16px;
  vertical-align: middle;
}
.sidebar-list li.item .column.text {
  color: #8b97b4;
  padding-left: 6px;
}
.sidebar-list li.item .column.text .title {
  color: #fff;
}
.sidebar-list li.item .column.text h5 {
  font-size: 15px;
  margin: 0;
}
.sidebar-list li.item .column.text span {
  font-size: 13px;
}
.sidebar-list li.item .column.button {
  padding-right: 10px;
}
.sidebar-list .column.logo {
  width: 17%;
}
.sidebar-list .column.text {
  width: 49%;
}
.sidebar-list .column.button {
  width: 31%;
}
.sidebar-list .more {
  text-align: center;
  text-transform: uppercase;
  margin-top: 13px;
  letter-spacing: 1px;
}
.sidebar-list .btn {
  margin: 0;
  float: right;
}

.email-section {
  padding: 40px 0;
  text-align: center;
  background-size: cover;
  margin-top: 20px;
  background-repeat: no-repeat;
}

.footer {
  background-color: #0B142A;
  color: #fff;
  padding-top: 15px;
}
.footer__logo {
  padding-top: 30px;
}
.footer h3 {
  display: none;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.footer a {
  color: #fff;
}
.footer ul {
  font-size: 14px;
  line-height: 22px;
  list-style-type: none;
  padding: 0;
}
.footer ul li {
  list-style-type: none;
}
.footer .widget {
  font-size: 15px;
  line-height: 26px;
  padding-top: 23px;
  padding-bottom: 0;
}
.footer .widget p {
  margin: 0;
}
.footer__bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 14px;
  line-height: 52px;
  margin-top: 30px;
}
@media (max-width: 1023px) {
  .footer {
    text-align: center;
  }
}
.footer__social {
  list-style-type: none;
  margin-top: 15px;
}
.footer__social li {
  display: inline-block;
  margin-right: 5px;
}

.list__header {
  background-position: center;
  background-size: cover;
  margin: 20px 0;
  padding: 30px;
}
.list__list {
  padding: 0;
}
.list__list .item {
  border: 1px solid #663399;
  list-style-type: none;
  margin-bottom: 15px;
  padding: 30px 20px;
  position: relative;
}
.list__list .item .ribbon {
  max-width: 200px;
  position: absolute;
  top: 0;
  left: 0;
}
.list__list .item.featured {
  padding: 60px 20px 30px;
}
.list__list .item.featured .column.rank {
  top: 50%;
}
.list__list .item .column {
  display: inline-block;
  vertical-align: middle;
}
.list__list .item .column.logo {
  text-align: center;
}
.list__list .item .column.logo a {
  text-decoration: none;
}
.list__list .item .column.logo a img {
  height: auto;
  margin-right: 10px;
  width: 90%;
}
.list__list .item .column.rank {
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  background-color: #CD3097;
  box-shadow: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  position: absolute;
  top: 40%;
  left: -15px;
}
.list__list .item .column.text {
  color: #8B97B4;
  margin-right: 10px;
  margin-left: 10px;
}
.list__list .item .column.text .title {
  color: #fff;
}
.list__list .item .column.text .stars {
  display: inline-block;
  letter-spacing: -2px;
}
.list__list .item .column.text .rating_number {
  display: inline;
  padding-left: 3px;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0;
}
.list__list .item .column.bonus .amount {
  display: block;
  font-size: 20px;
  font-weight: 600;
}
.list__list .item .column.button {
  text-align: center;
  float: right;
}
.list__list .item .column.button .btn {
  color: #fff;
  margin-bottom: 10px;
  padding: 5px 15px;
}
.list__list .item .column.button a {
  color: #CD3097;
  display: inline-block;
  padding-left: 20px;
}
.list__list .item .column.logo {
  width: 15%;
}
.list__list .item .column.text {
  width: 37%;
}
.list__list .item .column.bonus {
  width: 23%;
}
.list__list .item .column.button {
  width: 21%;
}
.list__list .custom_shortcode {
  border: 1px solid #cd3097;
  list-style-type: none;
  margin-top: 15px;
  padding: 14px 19px;
  padding-top: 20px;
  height: auto;
  display: flow-root;
}
.list__list .custom_shortcode .column.logo {
  width: 13%;
}
.list__list .custom_shortcode .column.text {
  width: 62%;
  font-size: 14px;
}
.list__list .custom_shortcode .column.button {
  width: 21%;
  display: inline-block;
  vertical-align: middle;
  float: none;
}
.list__list .custom_shortcode .column.button .btn {
  padding: 4px 22px;
  font-weight: normal;
  border-radius: 10px;
}
.list__list .custom_shortcode .column.button .read_more {
  padding-left: 6px;
}

.page-content {
  padding-top: 40px;
}

.standard {
  background: #fff;
}

.single-content {
  padding-top: 20px;
}

.updated {
  display: block;
  font-size: 10px;
  margin: 30px 0;
}

.post__pagination {
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.post__pagination li {
  list-style-type: none;
}
.post__pagination li.next {
  margin-left: auto;
}
.post__pagination span, .post__pagination a {
  color: black;
}
.post__pagination .current {
  font-weight: bold;
}
.post__pagination .prev a,
.post__pagination .next a {
  display: flex;
  align-items: center;
  color: #365596;
  font-style: italic;
  font-size: 12px;
  text-decoration: none !important;
}
.post__pagination .next a {
  margin-right: 30px;
}
.post__pagination .next a::after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='18' viewBox='0 0 24 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.2868 7.5124H1.33333C0.596954 7.5124 0 8.17842 0 9C0 9.82158 0.596954 10.4876 1.33333 10.4876H19.2868L14.9434 15.3335C14.3967 15.9435 14.3967 16.9325 14.9434 17.5425C15.4901 18.1525 16.3765 18.1525 16.9233 17.5425L23.5899 10.1045C24.1367 9.4945 24.1367 8.5055 23.5899 7.89551L16.9233 0.457494C16.3765 -0.152498 15.4901 -0.152498 14.9434 0.457494C14.3967 1.06749 14.3967 2.05648 14.9434 2.66647L19.2868 7.5124Z' fill='%23365596'/%3E%3C/svg%3E%0A");
  background-size: 100%;
  width: 12px;
  height: 9px;
  display: block;
  margin-left: 10px;
}
.post__pagination .prev a {
  margin-left: 30px;
}
.post__pagination .prev a::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='18' viewBox='0 0 24 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.71323 7.5124H22.6667C23.403 7.5124 24 8.17842 24 9C24 9.82158 23.403 10.4876 22.6667 10.4876H4.71323L9.05662 15.3335C9.60335 15.9435 9.60335 16.9325 9.05662 17.5425C8.50988 18.1525 7.62345 18.1525 7.07672 17.5425L0.410051 10.1045C-0.136684 9.4945 -0.136684 8.5055 0.410051 7.89551L7.07672 0.457494C7.62345 -0.152498 8.50988 -0.152498 9.05662 0.457494C9.60335 1.06749 9.60335 2.05648 9.05662 2.66647L4.71323 7.5124Z' fill='%23365596'/%3E%3C/svg%3E%0A");
  background-size: 100%;
  width: 12px;
  height: 9px;
  display: block;
  margin-right: 10px;
}

.entry__postmeta {
  border: 1px solid #eee;
  border-width: 1px 0;
  padding: 10px 0;
  display: flex;
  margin: 20px 0 25px;
}
.entry__postmeta > div {
  border-right: 1px solid #eee;
  padding: 0 10px;
}
.entry__postmeta > div:first-of-type {
  padding-left: 0;
}
.entry__postmeta > div:last-of-type {
  padding-right: 0;
  border-right: none;
}

body#tinymce {
  margin: 12px !important;
}

.container.table-section .breadcumb {
  margin-top: 20px !important;
  margin-bottom: 0;
}

.page-template-template-sections .breadcumb {
  margin-bottom: 0px;
  margin-top: 20px;
}

.single-list .breadcumb {
  margin-bottom: 0;
  margin-top: 20px;
}

.social_links {
  width: 6%;
  float: right;
}

.twitter.social_links {
  margin-left: 10px;
}

.archive.category .breadcumb {
  margin-top: 20px;
}

.archive.page .breadcumb {
  padding-top: 20px;
}

.page-template-template-article-archive .breadcumb {
  margin-bottom: 0;
  margin-top: 20px;
}

.list__header {
  margin-top: 13px;
}

.hero__container.text-center {
  text-align: center;
  width: 100%;
  left: 0;
}

.category .archive__list > .row div:first-child {
  min-height: 0;
}

span.title.japanese_title.hidden-lg {
  display: list-item;
}

.casino_item {
  padding-bottom: 12px;
  padding-top: 12px;
}

time.updated {
  margin-bottom: 10px;
}

.single-post h1.entry-title {
  font-size: 33px;
}

.casino-bonus__box p.title {
  font-weight: bold;
}

.stars span.active {
  display: inline-block;
  color: #ffba00;
}

.stars span {
  display: none;
}

.two-lists .btn {
  margin-bottom: 4px;
}

#mc_embed_signup .mc-field-group input {
  background: #0b142a;
}

#mc_embed_signup .mc-field-group input {
  display: block;
  width: 99%;
  padding: 8px 0;
  text-indent: 2%;
  border: 2px solid #0b142a;
  color: white;
  font-family: inherit;
  font-size: 13px;
}

#mc_embed_signup .button {
  clear: both;
  background-color: #ffa500;
  border: none;
  border-radius: 4px;
  transition: all 0.23s ease-in-out 0s;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: normal;
  height: 32px;
  line-height: 32px;
  margin: 0 0px 0px 0;
  padding: 0px 14px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  white-space: nowrap;
  width: auto;
}

#mc_embed_signup div.mce_inline_error {
  width: 99%;
}

p.comment-form-author {
  width: 80%;
  float: left;
}

.comment-respond {
  padding-bottom: 50px;
  margin-top: 20px;
}

.comments p.form-submit {
  width: 20%;
  float: right;
}

.comments textarea {
  font-size: 13px;
}

.comments input#submit {
  float: right;
  width: 100%;
  background: #cd3097;
  padding: 10px;
  color: white;
  box-shadow: none;
  border: 1px solid #cd3097;
  border-radius: 10px;
}

.comment_item {
  margin: 18px auto;
  background-color: #0d1832;
  padding: 20px;
}

.comment_item:first-child {
  margin-top: auto;
}

.comment_item:last-child {
  margin-bottom: auto;
}

.comment_author.row {
  margin-bottom: 16px;
}

.breadcumb {
  color: #8b97b4;
}

.breadcumb a {
  color: #8b97b4;
}

@media (max-width: 1199px) {
  .videos iframe {
    width: 100%;
  }
  .row.casino-review__row {
    display: block;
  }
  .casino-bonus__box {
    margin-top: 22px;
  }
  a.btn.casino-review__button {
    width: 47%;
    font-size: 12px;
  }
  .three-casinos__container {
    margin-bottom: 15px;
  }
  .two-lists li.item:before {
    display: none;
  }
  .filter__button {
    margin-bottom: 10px;
  }
  .two-lists .column.logo {
    width: 12%;
    margin-right: 5px;
  }
  .lastest-news_subtitle {
    padding: 0;
  }
  .two-lists li.item .column.logo a img {
    width: 100%;
    height: auto;
  }
  .two-lists li.item {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .two-lists li.item {
    padding-right: 6px;
    padding-left: 6px;
    border-bottom: 1px solid #2a334b;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .two-lists li.item:last-child {
    border-bottom: none;
  }
  .two-lists li.item a.btn {
    padding: 3% 5%;
    font-size: 12px;
    /* vertical-align: middle; */
    top: 13px;
    position: relative;
    width: 100%;
    text-align: center;
  }
  .item.nyheter img {
    width: 100%;
  }
  .social_links {
    float: none;
    display: inline-block;
    bottom: 17px;
    position: relative;
  }
  .two-lists li.item .column.button {
    width: 25%;
    float: right;
  }
  .two-lists .column.text {
    width: 59%;
  }
  .section.latest-news .col-sm-3.col-xs-6 {
    padding: 5px;
  }
  .section.latest-news {
    padding-right: 10px;
    padding-left: 10px;
  }
  .section.latest-news .col-sm-6 {
    padding: 0;
  }
  h3.hero__title {
    font-size: 24px;
  }
  h5.hero__subtitle {
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
  }
  two-lists li.item .column.text .title {
    font-weight: bold;
  }
  .latest-news .item .column.body {
    padding: 9px 10px;
  }
  .latest-news .item {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  section.videos .col-lg-4 {
    margin-bottom: 10px;
  }
  .widget_shame_widget {
    margin-bottom: 25px;
  }
  ul.casino-proscons__pros {
    margin-bottom: 12px;
  }
  .list__list .item .column.logo {
    width: 36%;
    display: block;
    margin: auto;
    margin-bottom: 25px;
  }
  .list__list .item .column.text .stars {
    clear: both;
    display: block;
    text-align: center;
    margin-bottom: 5px;
  }
  .list__list .item .column.text {
    width: 70%;
    display: block;
    margin: auto;
    text-align: center;
    color: white;
    margin-bottom: 25px;
  }
  .list__list .item .column.text br {
    display: none;
  }
  .list__list .item .column.bonus {
    width: 70%;
    margin: auto;
    display: block;
    text-align: center;
    margin-bottom: 25px;
  }
  .list__list .item .column.text .title a {
    margin-right: 0 !important;
  }
  .list__list .item .column.button {
    width: 46%;
    margin: auto;
    display: block;
    float: none !important;
  }
  .list__list .item .column.button a {
    padding-left: 0;
  }
  .list__list .item .column.logo a img {
    height: auto;
    width: 100%;
  }
  .list__list .item .column.button a {
    width: 100%;
  }
  .archive .page_navigation.navigation {
    width: 77%;
    display: block;
    height: 40px;
  }
  .list__list .custom_shortcode .column.logo {
    padding-top: 13px;
  }
  .list__list .custom_shortcode .column.text {
    padding-top: 0px;
  }
  .list__list .custom_shortcode .column.button .btn {
    font-size: 12px;
  }
}
.list__list.message-box .custom_shortcode {
  padding-top: 14px;
}
.list__list.message-box .custom_shortcode .column {
  display: block;
  text-align: center;
}
.list__list.message-box .custom_shortcode .column .btn {
  float: none;
  margin-bottom: 0;
}
.list__list.message-box .custom_shortcode .column:nth-child(2) {
  margin-top: 10px;
}

a.btn.casino-review__button:last-child {
  margin-left: 10px;
}

span.title {
  font-weight: bold;
}

.section.casino-intro {
  padding-bottom: 0;
}

.column.button {
  padding-right: 0 !important;
}

div.section.latest-news > div > div:nth-child(1) {
  margin-bottom: 5px;
}

section.section.three-casinos {
  padding-top: 0;
}

section h3 {
  font-size: 20px;
}

section.videos > div > div:nth-child(1) > div {
  margin-bottom: 15px;
}

.page_navigation.navigation {
  margin: auto;
  width: 26%;
  display: block;
  height: 20px;
}

.page_navigation.navigation div {
  float: left;
  width: 17%;
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}

.page_numbers {
  width: 66% !important;
  display: inline-flex;
}

.page_numbers a {
  display: inline-block;
  width: 21%;
  font-size: 12px;
  top: 2px;
  position: relative;
}

.page_numbers span {
  font-size: 12px;
  width: 21%;
  display: inline-block;
  top: 2px;
  position: relative;
  color: #8e8e8e;
}

.dotcenter {
  position: relative;
  bottom: 2px;
}

table {
  border-color: #1e2e4e;
  min-width: 90%;
  border-spacing: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.custom_box {
  background: #1e2e4e;
  padding: 15px;
  margin: 20px 0;
}

.contact .archive__list > .row div {
  min-height: 0;
}

form#gform_1 input, form#gform_1 textarea {
  background-color: #0b102a;
  border: none;
  color: white;
}

div#gform_wrapper_1 {
  background: #142346;
  padding: 20px;
}

form#gform_1 input#gform_submit_button_1 {
  clear: both;
  background-color: #5a6581;
  border: none;
  border-radius: 4px;
  -webkit-transition: all 0.23s ease-in-out 0s;
  transition: all 0.23s ease-in-out 0s;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  height: 39px;
  line-height: 32px;
  margin: 0;
  padding: 0 51px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  white-space: nowrap;
}

body .ubermenu-main .ubermenu-submenu .ubermenu-item-header > .ubermenu-target:hover {
  color: #cd3096;
}

body .ubermenu .ubermenu-target-text {
  font-weight: 400;
}

body .ubermenu-skin-minimal.ubermenu-horizontal .ubermenu-item-level-0 > .ubermenu-submenu-drop {
  border-top: 1px solid #cd3096;
}

.latest-news .item .column.body {
  min-height: 130px;
}

.archive .item .column.body {
  min-height: 125px;
}

@media (max-width: 991.98px) {
  .speltips > .row {
    flex-direction: column-reverse;
  }
}
.speltips__left-box {
  border-top: 5px solid #ef4136;
  background: #f3f3f3;
  padding: 20px 30px;
}
.speltips__left-box h3 {
  color: #000;
  font-size: 26px;
  margin-bottom: 15px;
}
.speltips__box-row {
  color: #000;
  margin-bottom: 8px;
}
.speltips__box-row span:first-of-type {
  font-weight: bold;
}
.speltips__box-row--image {
  display: flex;
  justify-content: center;
}
.speltips__postmeta {
  border: 1px solid #eee;
  border-width: 1px 0;
  padding: 10px 0;
  display: flex;
  margin: 20px 0 25px;
}
.speltips__postmeta > div {
  border-right: 1px solid #eee;
  padding: 0 10px;
}
.speltips__postmeta > div:first-of-type {
  padding-left: 0;
}
.speltips__postmeta > div:last-of-type {
  padding-right: 0;
  border-right: none;
}
.speltips__content,
.speltips__content h1,
.speltips__content h2,
.speltips__content h3,
.speltips__content h4,
.speltips__content h5,
.speltips__content h6,
.speltips__content a {
  color: rgba(0, 0, 0, 0.7);
}
.speltips__content a {
  text-decoration: underline;
}
.speltips__thumbnail-img {
  margin-bottom: 30px;
}
.speltips__casino-img {
  margin: 16px 0;
}
.speltips__cta {
  display: flex;
  padding: 15px 60px;
  line-height: 1.4em;
  font-size: 22px;
  margin-bottom: 3px;
  background: #3dbf1f;
  border: 1px solid #3dbf1f;
  color: #fff !important;
  box-shadow: 0 1px 0 0 #fff;
  width: 100%;
  font-weight: bold;
  justify-content: center;
  text-transform: uppercase;
}
.speltips__cta:hover {
  text-decoration: none;
  background: #22ad01;
  transition-duration: 0.1s;
}
.speltips .sidebar {
  position: relative;
  margin-bottom: 15px;
}

@media (max-width: 991.98px) {
  .poolspel > .row {
    flex-direction: column-reverse;
  }
}
.poolspel__left-box {
  border-top: 5px solid #ef4136;
  background: #f3f3f3;
  padding: 20px 30px;
}
.poolspel__left-box h3 {
  color: #000;
  font-size: 26px;
  margin-bottom: 15px;
}
.poolspel__box-row {
  color: #000;
  margin-bottom: 8px;
}
.poolspel__box-row span:first-of-type {
  font-weight: bold;
}
.poolspel__postmeta {
  border: 1px solid #eee;
  border-width: 1px 0;
  padding: 10px 0;
  display: flex;
  margin: 20px 0 25px;
}
.poolspel__postmeta > div {
  border-right: 1px solid #eee;
  padding: 0 10px;
}
.poolspel__postmeta > div:first-of-type {
  padding-left: 0;
}
.poolspel__postmeta > div:last-of-type {
  padding-right: 0;
  border-right: none;
}
.poolspel__content,
.poolspel__content h1,
.poolspel__content h2,
.poolspel__content h3,
.poolspel__content h4,
.poolspel__content h5,
.poolspel__content h6,
.poolspel__content a {
  color: rgba(0, 0, 0, 0.7);
}
.poolspel__content a {
  text-decoration: underline;
}
.poolspel__thumbnail-img {
  margin-bottom: 30px;
  width: 100%;
}
.poolspel__casino-img {
  margin: 16px 0;
}
.poolspel__cta {
  display: flex;
  padding: 15px 60px;
  line-height: 1.4em;
  font-size: 22px;
  background: #3dbf1f;
  border: 1px solid #3dbf1f;
  color: #fff !important;
  box-shadow: 0 1px 0 0 #fff;
  width: 100%;
  font-weight: bold;
  justify-content: center;
  text-transform: uppercase;
  text-decoration: none !important;
  margin-bottom: 20px;
}
.poolspel__cta:hover {
  text-decoration: none;
  background: #22ad01;
  transition-duration: 0.1s;
}
.poolspel .sidebar {
  position: relative;
  margin-bottom: 15px;
}

.author, .author h1, .author h3, .author h4, .author h5 {
  color: #212529;
}
.author h1 {
  font-size: 1.5em;
  margin: 15px 0 20px;
}
.author h3 {
  font-size: 12px;
  color: #4F6CA9;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
}
.author .btn {
  width: 100%;
}
.author__twitter {
  display: inline-block;
  margin-top: 20px;
  font-size: 18px;
}
.author__description {
  margin-top: 25px;
}
.author__image {
  width: 100%;
}
.author__section hr {
  border-top: 1px solid #4F6CA9;
  margin-top: 15px;
  margin-bottom: 30px;
}
.author__table {
  width: 100%;
}
.author__table table {
  margin: 0;
  min-width: 100%;
  margin-bottom: 30px;
}
.author__table h5 {
  font-size: 14px;
  color: #5c6ca3;
  font-weight: 700;
  margin-bottom: 8px;
}
.author__table tbody tr {
  display: flex;
}
.author__table tbody td {
  font-size: 13px;
}
.author__table tbody td a {
  text-decoration: underline;
  font-weight: 300;
  line-height: 1.1;
  display: inline-block;
}
.author__table tbody td:first-of-type {
  flex-grow: 1;
}
.andelar__filters .col > div {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 6px;
  margin: 0 0 20px;
}
.andelar__filters .col > div .deselect-all {
  display: flex;
  align-items: center;
  background: #e3e3e3;
  color: #000;
}
.andelar__filters .col > div .deselect-all::after {
  content: "";
  display: block;
  background: url(../images/icon-close.svg) no-repeat center/contain;
  width: 12px;
  height: 100%;
  margin: 2px 0 0 6px;
}
.andelar__filters .col > div a,
.andelar__filters .col > div button {
  margin: 0;
  padding: 3px 9px;
  text-transform: none;
}
.andelar__filters .col > div a:hover,
.andelar__filters .col > div button:hover {
  filter: brightness(1.05);
}
.andelar__filters .col > div a span {
  margin-left: 5px;
}
.andelar__filters .col > div a:hover {
  filter: brightness(1.2);
  color: #fff;
}
.andelar__filters .col > div a:focus {
  box-shadow: none;
}
.andelar__filters .col > div a:focus-visible {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.andelar__filters .col > div a.selected {
  outline: 3px solid rgba(0, 0, 0, 0.4);
}
.andelar__filters .col > div a.selected.one {
  outline: 6px solid #e3e3e3;
}
.andelar__content.loading {
  position: relative;
}
.andelar__content.loading::before {
  content: "";
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.6);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.andelar__user ul {
  padding: 0;
  margin: 0;
}
.andelar__user ul li {
  list-style-type: none;
}
.andelar__image {
  margin-bottom: 10px;
}
.andelar__link {
  display: flex;
  line-height: 1;
  background-color: #818a91;
  font-size: 15px;
  padding: 12px 24px;
  border-radius: 3px;
  color: #fff;
  fill: #fff;
  text-align: center;
  transition: all 0.3s;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
  justify-content: center;
  position: relative;
}
.andelar__link i {
  position: absolute;
  left: 24px;
}
.andelar__link i + span {
  padding: 0 0 0 20px;
}
.andelar__link:hover {
  transform: translateY(8px);
  text-decoration: none;
  color: white;
}