section.section.filtered-post-grid {
  .card {
    background-color: #f5f5f5;
    border-radius: 0;

    &.card-btop {
      border-top: 5px solid #e14862;
    }

    > .list-group {
      border-top: 1px solid rgba(0, 0, 0, 0.125);
    }
  }

  .card-title {
    font-size: 12px;
    margin: 0;
  }

  .list-group-item {
    background-color: transparent;

    &.active {
      background-color: #e1486257;
      border-color: #e14862;
      color: #000;
    }
  }

  .filter-info {
    text-align: center;
    line-height: 1.2;
    padding: 20px;
    border-radius: 3px;

    .post-count {
      font-size: 28px;
      font-weight: 900;
    }

    &.filtered {
      .btn {
        display: block;
      }
    }

    .btn {
      display: none;
      border-radius: 3px;
      padding: 10px 10px;
    }
  }

  .filter-info-mobile {
    display: none;

    a {
      font-size: 12px;
    }
  }

  .post-card {
    border: none;

    a {
      text-decoration: none;
    }

    .post-card-top {
      position: relative;
      border-radius: 3px;
      overflow: hidden;
      background: #d1d8e8;
    }

    .hover-info {
      display: flex;
      flex-direction: column;
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      transition: all 0.3s ease;
      align-items: center;
      padding: 10px;
      background: rgba(73, 90, 147, 0.85);
      z-index: 10;

      .btn {
        margin: auto;
        margin-bottom: 0;
      }
    }

    .card-body {
      padding: 10px 0 5px;
    }

    /* .card-details,
    .card-details span,
    .card-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    } */

    .card-details {
      margin: 0;
      font-size: 12px;
    }

    .card-title {
      font-size: 14px;
      margin: 0;
    }
  }

  .input-group-text {
    background-color: transparent;
  }

  .navigator-header {
    > .col {
      > .input-group {
        background-color: #F5F5F5;
        border: 1px solid rgba(0,0,0,.125);
        padding: 16px;
      }
    }
  }
}

.game-provider-list {
  a {
    cursor: pointer;

    &:hover {
      color: #000000;
      text-decoration: none;
      background-color: #ea96a4;
    }
  }
}

.modal {
  .game-provider-list.list-group {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;

    &.list-group-flush {
      /* .list-group-item:last-child {
            border-bottom-width: 1px;
          } */
    }

    .list-group-item {
      flex: 0 0 50%;
      width: 50%;
      border-left: 0;

      &:nth-child(2n + 1) {
        border-right: 1px solid rgba(0, 0, 0, 0.125);
      }

      &:nth-child(3n + 2) {
        /* border-left: 0;
            border-right: 0; */
      }

      &:nth-last-child(2) {
        border-bottom-width: 0;
      }

      /* .description {
            font-size: 10px;
          } */
    }
  }
}

.svg-inline--fa {
  width: 0.5em;
}
