.three-casinos {
	&__container {
		background-position: center;
		background-size: cover;
		height: 188px;
		padding: 25px;
	}

	&__logo {
		display: flex;
		height: 95px;
		margin-bottom: 5px;
		text-align: center;
		align-items: center;
		justify-content: center;

		img {
			max-height: 95px;
		}
	}

	&__button {
		background-color: rgba(255,255,255, 0.3);
		border: 1px solid rgba(255,255,255, 0.3);
		text-align: center;
		width: 47%;

		&.transparent {
			background-color: transparent;
			border: 1px solid $white;
		}

		&:last-child {
			float: right;
			margin-right: 13px;
		}
	}
}