.faq {
  background-size: cover;
  background-position: center;
  color: $white;
  //min-height: 560px;
  position: relative;
  overflow: hidden;
  //text-align: center;

  h2 {
    width: 100%;
    font-size: 23px;
    text-align: center;
    text-transform: uppercase;
  }

  &__content {
    text-align: center;
    margin-bottom: 1rem;
    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__image {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &__item {
    flex: 1;
    background: #fbfbfb;
    padding: 5px 20px;
    margin-bottom: 4px;
    list-style-type: none;

    &:hover {
      background-color: #ffead5;
      cursor: pointer;
    }

    h3 {
      font-size: 18px;
      padding: 12px 3px 10px 0;
      margin: 0;
      display: flex;
      align-items: center;
      span {
        flex-grow: 1;
      }
      .fas {
        &::before {
          content: '\f078';
        }
      }
    }

    .faq__item-content {
      overflow: hidden;
      transition: height 0.25s ease;
      a {
        color: #ef4136;
        border-bottom: 2px solid #ef4136;
        &:hover {
          border-bottom: 0;
          text-decoration: none;
        }
      }
      ul {
        margin: 4px;
        padding-left: 10px;
        li {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.7);
          line-height: 1.7em;
          list-style: none;
          list-style: outside;
          list-style-type: none;
          list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjdGQ0IyMjM3Mzk3QjExRTc5N0UxQjI0RENGQjRCQkQyIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjdGQ0IyMjM4Mzk3QjExRTc5N0UxQjI0RENGQjRCQkQyIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6N0ZDQjIyMzUzOTdCMTFFNzk3RTFCMjREQ0ZCNEJCRDIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6N0ZDQjIyMzYzOTdCMTFFNzk3RTFCMjREQ0ZCNEJCRDIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7DZf0BAAABPUlEQVR42mL8//8/A6mABZnzfPEyhs/nzsUxsbG/AnJ3gMT+//7NwKmsxCCTnYFQCLIJjP/+ZbwYGJa7j5P//0FhqR9A7AHEDPu5BBjOu3kj1AEx3KZ/P36y/nn/IYVVUJCBkY2NHSi0FoiDGVmYdzBxcaE4jwnO4OL8xaWqEvjv9+8bUCEuqEYPBkZG7JqgTr0HVsTAcBlF479/3jg1QcFDIE4A4l9gBaysXN8fPlz8+/VrAXyahIG4H4jZwH798+cfh4xMIauw8EesQc7IzCwKpDYBsQUsfIBujmdkZV3CwMSE6bx/33+wfrtzdwMTCwtCAwNDPBAvYUBLAHCbgKb9Zebm2vPn82crpj9/GWAa/n3/BoyOHzg0sTD/E3Z1rmfm4vzCxMH5AmwDKER//WTg0tRA0cRITtoDCDAA7LV4PVdALkEAAAAASUVORK5CYII=);
        }
      }
    }

    * {
      line-height: 1.7em;
      color: rgba(0, 0, 0, 0.7);
    }
    &:not(.opened) {
      .faq__item-content {
        display: none;
      }
    }
    &.opened {
      .faq__item-content {
        display: block;
      }

      h3 {
        color: #ef4136;
        .fas {
          &::before {
            content: '\f077';
          }
        }
      }
    }
  }
}
