.section-table-no-deposit {
	.list__list {
		.item {
			.column {
				&.bonus {
					width: 63%;
				}
			}
		}
	}
}