.two-lists {
    overflow: hidden;

    h2 {
	    background-size: 10px;
	    color: #fff;
	    font-size: 13px;
	    letter-spacing: 1px;
	    line-height: 32px;
	    margin-bottom: 10px;
	    overflow: hidden;
	    padding: 0;
	    padding-left: 15px;
	    text-transform: uppercase;
	}

	ol {
	    counter-reset: item;
	    margin: 0;
	    padding: 0;
	}

	li {
		&.item {
		    overflow: hidden;
		    margin-bottom: 5px;
			padding: 8px 0;
			border-bottom: 1px solid #2a334b;
		    counter-increment: item;

		    &:before {
			    content: counter(item);
			    font-size: 13px;
			    color: #ccc;
			    text-align: center;
			    display: inline-block;
			    vertical-align: middle;
			    width: 5%;
			}

			.column {
			    display: inline-block;
			    vertical-align: middle;

			    &.logo {

			    	text-align: center;

			    	a {
					    text-decoration: none;

					    img {
						    width: 50px;
						    height: 50px;
						    border-radius: 5px;
						    vertical-align: middle;
						    margin-right: 5px;
						}

						h4 {
						    display: inline-block;
						    font-weight: 600;
						    color: $orange;
						    font-size: 16px;
						    vertical-align: middle;
						}
					}
			    }

			    &.text {
					color: #8B97B4;
					width: 60%;

			    	.title {
			    		color: $white;
			    	}
			    }

				&.button {
					padding-right: 10px;
					float: none;
					width: 20%;
				}
			}
		}
	}

	.column {
		&.logo {
	    	width: 12%;
	    }

	    &.text {
		    width: 45%;
		}

		&.button {
		    width: 18%;
		}
	}

	.more {
		text-align: center;
		padding-top: 10px;

		a {
			font-size: 12px;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: 1px;
		}
	}
}

@include upToTablet {

	.section-table-small {
		li {
			&.item {
				&:before {
				    width: 4%;
				}

				.column {
					&.logo {
					    text-align: center;
					    padding-right: 5px;

					    a h4 {
						    margin-top: 2px;
						    font-size: 11px;
						    display: block;
						}
					}

					&.bonus-text {
					    width: 48%;

					    p {
						    font-size: 15px;
						    line-height: 20px;
						}
					}

					&.button a.btn {
					    font-size: 12px;
					}
				}
			}
		}
	}
}