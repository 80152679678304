.section-table {

	table {
	    display: block;
	}

	thead {
	    display: block;
	}

	tr {
		&.sort {
		    background: $orange;
		    display: block;
		    margin-bottom: 10px;
		    overflow: hidden;

		    th {
			    display: block;
			    float: left;
			    text-align: left;
			    color: #fff;
			    font-size: 13px;
			    background-size: 10px;
			    line-height: 32px;
			    text-transform: uppercase;
			    letter-spacing: 1px;
			}
		}

		&.item {
		    display: block;
		    background: #fbfbfb;
		    border-bottom: 1px solid #ccc;
		    margin-bottom: 5px;
		    overflow: hidden;
		    padding: 8px 0;
		    width: 100%;

		    &.item-1, 
			&.item-2, 
			&.item-3 {
			    padding: 12px 0;
			}

			&:hover {
			    background: #efefef;
			}

			p {
			    margin-bottom: 0;
			    color: #000;
			    font-size: 14px;
			    line-height: 35px;

			    span.mobile-text {
				    display: none;
				}
			}

			td {
				&.column {
				    display: block;
				    float: left;
				    min-height: 1px;

				    &.button {
				    	text-align: center;
					    padding-right: 10px;
					}

					&.count {
					    text-align: center;

					    span {
						    display: block;
						    font-size: 18px;
						    color: #ccc;
						    margin: auto;
						    line-height: 35px;
						}
					}

					&.logo a {
					    text-decoration: none;

					    img {
						    width: 35px;
						    height: 35px;
						    border-radius: 5px;
						    vertical-align: middle;
						    margin-right: 5px;
						}

						h4 {
						    display: inline-block;
						    font-weight: 600;
						    color: $orange;
						    font-size: 16px;
						    vertical-align: middle;
						}
					}

					&.bonus-text p {
					    font-size: 20px;
					    color: #000;
					    line-height: 24px;
					    padding: 4px 0;

					    i {
						    font-size: 14px;
						    font-weight: 400;
						    font-style: normal;
						}	
					}	

					&.turnover strong {
					    font-size: 20px;
					}    
				}
			}
		}
	}

	.count {
	    width: 50px;
	}

	.logo {
	    width: 180px;
	}

	.bonus-text {
	    width: 240px;
	}

	.bonus-code {
	    width: 150px;
	}

	.turnover {
	    width: 200px;
	}

	.free-spins {
	    width: 150px;
	}

	.button {
	    width: 170px;
	}

	.read-more {
	    text-align: right;
	    margin-top: 10px;
	}
}


@include upToTablet {
	.section-table {

		.count {
		    width: 10%;
		}

		.bonus-text {
		    width: calc(90% - 105px);
		}

		.turnover {
		    width: calc(90% - 105px);
		}

		.free-spins {
		    width: calc(90% - 105px);
		}

		.button {
		    width: 105px;
		}

		tr {
			&.sort th span {
			    font-size: 12px;
			}

			&.item {
			    position: relative;

			    td.column {
				    margin-left: 10%;
				    display: block;
				    float: left;

				    &.button {
					    margin-left: 0;
					    position: absolute;
					    right: 0;
					    top: 50%;
					    margin-top: -17px;

					    a.btn {
						    font-size: 10px;
						}
					}

				    &.count {
					    position: absolute;
					    left: 0;
					    top: 50%;
					    margin-top: -8px;
					    margin-left: 0;

					    span {
						    display: block;
						}

						&.logo {
						    margin-bottom: 6px;
						}

						&.bonus-text {
						    margin-bottom: 2px;

						    p {
							    font-size: 20px;
							    line-height: 1.05em;

							    i {
								    font-size: 14px;
								}
							}
						}
					}

					&.turnover strong {
					    font-size: 14px;
					}					
				}

				p {
				    font-size: 11px;
				    line-height: 1.4em;

				    span.mobile-text {
					    display: inline;
					    padding-right: 3px;
					}
				}
			}
		}
	}
}