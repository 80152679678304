.cards-toplist {
    background: #f5f5ff;
    overflow: hidden;
    position: relative;

    h2 {
        width: 100%;
        font-size: 23px;
        text-align: left;
        text-transform: uppercase;
    }

    &__image {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        bottom: 0;
        object-fit: cover;
    }

    &__content {
        text-align: center;
        margin-bottom: 1rem;
        p {
            text-align: left;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &__list {
        list-style-type: none;
        padding: 0;
        width: 100%;
        margin: 0;
        display: grid;
        gap: 30px;
        grid-template-columns: 1fr 1fr 1fr;

        @media(max-width: 767px) {
            display: flex;
            flex-direction: column;
            gap: 0;
        }

        & > li {
            max-width: 100%;
            position: relative;
            display: grid;
            grid-template: repeat(4,auto)/repeat(4,25%);
            margin-bottom: 10px;
            padding: 0 5px 5px 5px;
            border-top: 3px solid #5c6ca3;
            background: transparent;
            //background: #fff;
            @media(max-width: 767px) {
                margin-bottom: 5px;
            }
            &:last-of-type {
                @media(max-width: 767px) {
                    margin-bottom: 0;
                }
            }

            & > div {
                overflow: hidden;
                padding: 10px;
                font-size: 14px;
                color: #606366;
                border-bottom: 1px solid #eee;

                .content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }

        .cards-toplist__cell {
            &--number {
                display: block;
                position: absolute;
                top: -10px;
                left: 0;
                border: none !important;
            }

            &--cta {
                grid-column: 1/span 4;
                grid-row: 4;
                order: 5;
                width: auto;
                padding: 10px 0 0;
            }

            &--cta,
            &--read_review,
            &--disclaimer {
                border-bottom: none;
            }

            &--read_review,
            &--disclaimer {
                grid-column: 1/span 4;
                padding: 0;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &--disclaimer {
                align-items: flex-end;
            }
            &--logo {
                grid-column: 1;
                grid-row: 1;
                order: 0;
                display: flex;
                padding-left: 0;
            }

            &--bonus {
                grid-column: 1/span 4;
                grid-row: 2;
                //order: 1;
                padding-left: 10px !important;
            }

            &--pros {
                grid-column: 1/span 4;
                grid-row: 3;
                //order: 1;
                padding-left: 10px !important;
            }

            &--rating {
                grid-column: 2/span 2;
                grid-row: 1;
                order: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 10px !important;
            }

            &--license {
                grid-column: 4;
                grid-row: 1;
                order: 2;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .toplist__cell {
        &-number {
            border-radius: 0;
            border-bottom-right-radius: 3px;
            background: #5c6ca3;
            color: #fff;
        }

        &-cta {
            width: 100%;
            //.content {
            //    display: flex;
            //    flex-direction: column-reverse;
            //}
        }

        &-read_review {
            margin-top: 12px;
        }

        &-disclaimer {
            margin-top: 10px;
            background: #eee;
            padding: 10px 20px;
            width: 100%;

            p {
                line-height: 1;
            }
        }
    }
}