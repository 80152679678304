.latest-news {
	background-color: $blue;
	margin-top: 20px;
	padding-top: 25px;
	padding-bottom: 40px;

  	.title {
		font-size: 20px;
    }

    .seeall {
    	text-transform: uppercase;
    }

	.item {
	    overflow: hidden;

		.column {

		    &.image {
			    position: relative;
			}

			&.body {
				background-color: #1E2D51;
				color: #8B97B4;
				padding: 20px;
				min-height: 100px;

			    .info {
				    margin-bottom: 5px;

				}
			}
		}

		@include upToDesktop {
			margin-bottom: 20px;
		}
	}
}
