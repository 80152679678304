.countdown-timer {
    background: #D6DBE5;
    .countdown-timer__items {
        overflow-x: scroll;
        @include media-breakpoint-down(sm) {
            overflow-x: hidden!important;
        }
    }
    &--count-1,
    &--count-2 {
        .countdown-timer__items {
            @include media-breakpoint-up(xl) {
                overflow-x: hidden;
            }
        }
    }
    &--count-1 {
        .countdown-timer__items {
            overflow-x: hidden!important;
        }
    }


    &__inner {
        display: flex;
        justify-content: center;
        padding: 50px 0;
        align-items: center;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
            padding: 40px 0 60px 0;
        }
        @include media-breakpoint-up(md) {
            padding: 44px 0 51px 0;
        }
        @include media-breakpoint-up(lg) {
            padding: 50px 0;
        }

        h3 {
            color: #294377;
            font-size: 40px;
            line-height: 47px;
            width: 100%;
            text-align: center;
            margin: 0 0 30px;
            @include media-breakpoint-up(md) {
                margin: 0 20px 0 0;
                max-width: 295px;
            }
            @include media-breakpoint-up(lg) {
                margin: 0 80px 0 0;
            }
        }
    }

    &--count-1 {
        .countdown-timer__inner {
            h3 {
                @include media-breakpoint-up(md) {
                    margin: 0 50px 0 0;
                }
                @include media-breakpoint-up(lg) {
                    margin: 0 50px 0 0;
                }
            }
        }
    }

    &__items {
        display: flex;
        overflow-x: scroll;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
            overflow-x: hidden;
        }

        & > div {
            max-width: 340px;
            &:not(:last-of-type) {
                margin: 0 0 30px;
                @include media-breakpoint-up(md) {
                    margin: 0 30px 0 0;
                }
                @include media-breakpoint-up(lg) {
                    margin: 0 80px 0 0;
                }
            }

            a {
                &:hover {
                    text-decoration: none;
                    color: #fff;
                }
            }
        }
    }

    &__item {
        display: flex;
        padding: 10px 15px;
        color: #fff;
        border-radius: 5px;
        height: 100%;
        align-items: center;
        background-color: #21407D;

        strong {
            font-size: 14px;
            font-weight: normal;
        }

        &--days {
            .time-part__colon {
                &.minute {
                    display: none;
                }
            }
            .time-part {
                &.second {
                    display: none;
                }
            }
        }

        &--hours {
            .day {
                display: none;
            }
        }

        .time-part {
            //display: flex;
            //flex-direction: column;
            //display: flex;
            width: 58px;
            text-align: center;
            &::after {
                content: attr(aria-label);
                display: block;
                font-size: 10px;
                line-height: 12px;
                text-align: center;
                text-transform: uppercase;
            }

            span {
                font-size: 10px;
                text-align: center;
                text-transform: uppercase;
            }

            &__colon {
                margin-top: -2px;
                width: 14px;
                text-align: center;
                font-size: 40px !important;
            }

            &__list {
                display: flex;

                & > div {
                    font-style: normal;
                    font-size: 50px;
                    line-height: 52px;
                }
            }
        }

        &-left {
            min-width: 80px;
            width: 80px;
            height: 80px;
            max-height: 80px;
            border-radius: 5px;
            margin-right: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-right {
            display: flex;
            flex-direction: column;

            strong {
                line-height: 16px;
            }
        }
    }
}