.speltipslist {
    background: #f5f5ff;
    &__title {
        border-bottom: 3px solid #d1d8e8;
        margin-bottom: 20px;
        display: flex;
        h2 {
            color: #212529;
            border-bottom: 3px solid #8395c6;
            margin-bottom: -3px;
            display: inline-block;
            line-height: 1.25;
            font-size: 18px;
        }
    }
    &__filter {
        &-nav {
            display: flex;
            align-items: center;
            font-size: 12px;
            margin-bottom: 10px;
            white-space: nowrap;
            position: relative;
            color: #212529;
        }
        &-menu {
            padding: 0;
            margin: 0;
            display: flex;
            flex-flow: row;
            list-style: none;
            color: #fff;
            font-weight: 600;
            letter-spacing: 1.1px;
            margin-left: 5px;
            li {
                text-decoration: none;
                font-family: -apple-system,system-ui,BlinkMacSystemFont,segoe ui,Roboto,Ubuntu,sans-serif;
                text-transform: uppercase;
                position: relative;
                display: flex;
                margin: 0 5px;
                padding: 0 10px 0 0;
                flex-direction: column;
                letter-spacing: normal;
                &:not(:last-of-type) {
                    border-right: 1px solid #d1d8e8;
                }
                color: #212529;
                a {
                    text-decoration: none;
                    color: inherit;
                    margin: auto;
                }
            }
        }
    }
    &__table {
        width: 100%;
        margin: 0;
        thead {
            th {
                color: #fff;
                text-align: left;
                font-size: 14px;
                padding: 10px;
            }
            background: #5c6ca3;
        }
        tbody {
            td {
                overflow: hidden;
                border-bottom: 1px solid #eee;
                padding: 10px;
                font-size: 14px;
                color: #606366;
                .content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                a {
                    color: #606366;
                }
            }
        }
    }
    &__pagination {
        padding: 0;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        li {
            list-style-type: none;
            &.next {
                margin-left: auto;
            }
        }
        span, a {
            color: black;

        }
        .current {
            font-weight: bold;
        }
    }
    @include media-breakpoint-down(sm) {
        &__table {
            thead {
                th {
                    display: none;
                }
            }
            tbody {
                tr {
                    max-width: 100%;
                    position: relative;
                    display: grid;
                    grid-template: repeat(4,auto)/repeat(4,25%);
                    margin-bottom: 10px;
                    padding: 5px;
                    border-top: 3px solid #5c6ca3;
                    padding-top: 0;
                    td {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }
        &__filter {
            &-nav {
                align-items: flex-start;
            }
            &-menu {
                overflow-x: scroll;
                padding-bottom: 5px;
            }
        }
    }
}