.comment {
	&-respond {
		background-color: $blue;
		margin-bottom: 20px;
		padding: 20px;
	}

	&-list {
		background-color: $blue;
		margin-bottom: 20px;
		padding: 20px;

		li {
			list-style-type: none;
		}
	}
}

.comments {
	input[type="text"],
	textarea {
		background-color: $dark-blue;
		border: 0;
		color: $white;
		padding: 10px;
		-webkit-appearance: none;
	}

	textarea {
		width: 100%;
	}
}