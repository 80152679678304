
.gform_wrapper {

	.gform_button {
		background-color: $blue;
		border-radius: 0;
		box-shadow: none;
		color: $white;
		cursor: pointer;
		display: inline-block;
		font-family: $font-heading;
		font-size: 14px;
		font-weight: 700;
		letter-spacing: 0.5px;
		line-height: normal;
		margin-bottom: 20px;
		padding: 20px 30px;
		text-transform: uppercase;
		width: 100%;
		-webkit-appearance: none;

		@include upToTablet {
			margin-bottom: 30px;
		}
	}


	input,
	textarea {
		background: #FFFFFF;
		border: 1px solid #ECECEC;
		padding: 3px 10px;
	}

	.gfield_label {
		font-weight: 500 !important;
	}

	.gfield_radio, 
	.gfield_checkbox {

		li {
			display: inline-block;
			float: left;
			margin-right: 10px;

			input {
				appearance: none;		
				background: #fff;
				border: 1px solid $form-grey;
			    color: $form-grey;
			    cursor: pointer;
			    display: inline-block;
			    height: 20px;
			    margin-right: 0.5rem;
			    outline: none;
			    padding: 0;
				width: 20px !important;
			    vertical-align: top;	
			    z-index: 99;	
			}

			label {
				font-size: 12px;
			}
		}
	}
}	


.footer {

	input[type="text"], 
	textarea {
		color: $white;
		line-height: 20px;
		width: 100%;
	}

	.gform_wrapper {

		input,
		textarea {
			background: #1B1B1B;
			border: 0;
			border-radius: 0;
			box-shadow: none;
			padding: 3px 10px;
		}

		div.validation_error {
			background-color: #790000;
			color: $white !important;
		}

		div.validation_message {
			display: none !important;
		}

		li.gfield.gfield_error {
		    background-color: transparent !important;
		    border: 0 !important;
		    margin-bottom: 8px !important;
		    padding: 0 !important;
		}

		li.gfield.gfield_error.gfield_contains_required div.ginput_container {
		    margin-top: 8px !important;
		}

		li.gfield.gfield_error.gfield_contains_required label.gfield_label {
			margin-top: 0 !important;
		}

		.validation_message {
			color: #ff0000;
			font-weight: 700;
		}

		.gfield_label {
			color: $medium-grey;
			display: block;
			font-size: 14px !important;
			margin-bottom: 5px;
		}

		.gfield {
			margin-top: 0 !important;
			margin-bottom: 10px;
		}

		.gform_button {
			background: $grey;
			border: none;
			border-radius: 0;
			color: $white;
			cursor: pointer;
			font-size: 14px;
			line-height: 21px;
			margin-top: 30px;
			padding: 5px 20px;
			text-transform: uppercase;
			width: 100%;
			-webkit-appearance: none;

			@include upToTablet {
				margin-bottom: 30px;
			}
		}

		.gfield_radio, 
		.gfield_checkbox {

			li {
				display: inline-block;
				float: left;
				margin-right: 10px;

				input {
					appearance: none;		
					background: #fff;
					border: 1px solid $form-grey;
				    color: $form-grey;
				    cursor: pointer;
				    display: inline-block;
				    height: 20px;
				    margin-right: 0.5rem;
				    outline: none;
				    padding: 0;
					width: 20px !important;
				    vertical-align: top;	
				    z-index: 99;	

				    &::before {
				      content: '';
				      display: inline-block;
				      font-size: 18px;
				      line-height: 18px;
				      text-align: center;
				      vertical-align: top;
				    }

					&:checked {
					    &::before {
					      content: '\2573';
					      display: inline-block;
					      font-size: 18px;
					      line-height: 18px;
					      text-align: center;
					      vertical-align: top;
					    }
					}
				}

				label {
					font-size: 12px;
				}
			}
		}
	}	
}
